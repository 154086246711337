import { BaseURL } from "../baseURl";
import axios from "axios";

interface ResponseRegisterRouter {
  message: string;
  status: number;
}

interface RegisterRouterParams {
  email: string;
  password: string;
}

export const RegisterRouter = async ({
  email,
  password,
}: RegisterRouterParams): Promise<ResponseRegisterRouter> => {
  const response = await axios
    .post<ResponseRegisterRouter>(`${BaseURL}/user/register`, {
      email,
      password,
    })
    .then((res) => {
      const status = res.status;
      const message = res.data.message;

      return { status, message };
    })
    .catch((err) => {
      const status = err.response.status;
      const message = err.response.data.message;

      return { status, message };
    });

  return response;
};
