import React from "react";
import notfound from "../assets/images/notfound.png";
import notfoundbot from "../assets/images/notfound-bot.png";
import { useNavigate } from "react-router-dom";
import Button from "../common/Button.common";
import { WEBSITE_PATH } from "../config/Path";

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-yellow_theme flex items-center flex-col justify-center">
      <div>
        <img src={notfound} alt="notfound" />
      </div>
      <div className="mt-10">
        <Button
          onClick={() => navigate(WEBSITE_PATH.HOME)}
          text="back to home page"
        />
      </div>
    </div>
  );
};

export default NotFound;
