import React, { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import Button from "../common/Button.common";
import { ResearchStoreImpl } from "../store/ResearchStore";
import { useParams } from "react-router-dom";
import type { ResearchType } from "../interfaces/ResearchType";

interface DetailProps {
  researchStore: ResearchStoreImpl;
}

const Detail: React.FC<DetailProps> = (props) => {
  const { id } = useParams();
  const [data, setData] = useState<ResearchType>({} as ResearchType);

  useEffect(() => {
    if (id) {
      setData(props.researchStore.getResearchById(id));
    }
  }, [id, props.researchStore, data]);

  const Topic: { [key: string]: string } = {
    Title: "title",
    Researcher: "researcher_id",
    journal: "journal",
    "Public Year": "publish_year",
    Quartile: "quartile",
    "Impact Factor": "impact_factor",
  };

  return (
    <div className="w-[60vw] h-[80vh] bg-white max-md:w-[70vw] max-lg:h-fit max-lg:my-5">
      <div className="flex items-center justify-center">
        <p className="text-[3vw] max-md:text-[6vw] max-md:font-bold py-5">
          Research Description
        </p>
      </div>
      <div className="border-2 rounded-lg">
        {Object.keys(Topic).map((item, index) => {
          return (
            <div className="border-b-[1px] px-10 py-2" key={index}>
              <div className="flex gap-5 items-center">
                <p className="font-bold max-md:text-[3.5vw]">{item}</p>
                <IoIosArrowForward className="max-md:w-2 max-md:h-2" />
                <span className="max-md:text-[2vw]">
                  {item === "Researcher"
                    ? data.researcher_id?.english_name
                    : data[Topic[item] as keyof ResearchType]}
                </span>
              </div>
            </div>
          );
        })}
        <div className="flex justify-center mt-7 mb-3">
          <Button onClick={() => {}} text="download" />
        </div>
      </div>
    </div>
  );
};

export default Detail;
