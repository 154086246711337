import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CarouselSlider from "../components/Carousel/CarouselSlider";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { MdOutlineScience } from "react-icons/md";
import { BiStats } from "react-icons/bi";
import { ValidateToken } from "../api/authorization/validateToken";
import { WEBSITE_PATH } from "../config/Path";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [admin, setAdmin] = useState(false);

  // useEffect(() => {
  //   const validateToken = async () => {
  //     const response = await ValidateToken();

  //     if (!response.valid) {
  //       // navigate(WEBSITE_PATH.LOGIN);
  //     } else if (response.role === "admin" && response.valid) {
  //       setAdmin(true);
  //     } else if (response.role === "user" && response.valid) {
  //       setAdmin(false);
  //     }
  //   };

  //   validateToken();
  // }, [navigate]);

  const handleClick = (val: string) => {
    navigate(val);
  };

  return (
    <div className="min-h-[110vh] flex flex-col">
      <Navbar />
      <div>
        <div className="mt-[9vh] shadow-lg">
          <CarouselSlider />
        </div>
        <div className="h-fit">
          <div className="py-5 flex justify-center flex-col">
            <div className="text-[3vw] font-light text-center leading-tight bg-[#f1f1f3] py-3 text-[#626a74] w-full">
              <p>
                ระบบจัดการฐานข้อมูลงานวิจัยของภาควิชาคณิตศาสตร์
                มหาวิทยาลัยเทคโนโลยีพระจอมเกล้าธนบุรี
              </p>
              <p className="text-slate-400 max-lg:hidden">
                The Management System of Mathematics Research Database, King
                Mongkut's University of Technology Thonburi
              </p>
            </div>
            <div className="p-14 flex items-center justify-center gap-x-20 gap-y-4 max-md:flex-col">
              <div
                className="w-[17vw] h-[10vh] bg-[#ff4a13cc] rounded-md flex hover:bg-[#626a74] duration-150 ease-in-out cursor-pointer max-md:w-[30vw]"
                onClick={() => handleClick(WEBSITE_PATH.STATISTIC)}
              >
                <div className="m-auto flex items-center gap-3">
                  <BiStats color="#f1f1f3" />
                  <span>
                    <p className="text-[#f1f1f3] text-[1vw] max-md:text-[2vw]">
                      Statistic & Researher Info
                    </p>
                  </span>
                </div>
              </div>
              <div
                className="w-[15vw] h-[10vh] bg-[#ff4a13cc] rounded-md flex hover:bg-[#626a74] duration-150 ease-in-out cursor-pointer max-md:w-[30vw]"
                onClick={() => handleClick(WEBSITE_PATH.RESEARCH)}
              >
                <div className="m-auto flex items-center gap-3">
                  <MdOutlineScience color="#f1f1f3" />
                  <span>
                    <p className="text-[#f1f1f3] text-[1.5vw] max-md:text-[2vw]">
                      Research
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
