import React, { useEffect, useState } from "react";
import { SingleValue } from "react-select";
import ResearchChart from "../components/Chart/ResearchChart";
import Navbar from "../components/Navbar";
import ResearcherInfo from "../components/ResearcherInfo";
import Selected from "../components/Selected";
import { ChartStoreImpl } from "../store/ChartStore";
import { observer } from "mobx-react";
import { Research_type_option } from "../mocks/research_type_option";
import AccessTokenProvider from "../provider/AccessTokenProvider";
import type { Research_Option } from "../interfaces/ResearchOption";

interface ChartProps {
  chartStore: ChartStoreImpl;
}

const Chart: React.FC<ChartProps> = observer((props) => {
  const [researcher, setResearcher] = useState<{
    value: string;
    label: string;
  }>({ value: "ALL", label: "ทั้งหมด" });
  const [researchType, setResearchType] = useState<string>("ALL");
  const [researchOption, setResearchOption] = useState<Research_Option>(
    {} as Research_Option
  );

  useEffect(() => {
    async function fetch() {
      setResearchOption(await props.chartStore.fetchStatistic("ALL"));
    }

    setResearchOption(props.chartStore.researcher_research_statistic); // <- comment this line to fetch data from server

    // fetch(); // <- uncomment this line to fetch data from server
  }, [props.chartStore]);

  const handleChangeResearchType = (value: SingleValue<any>) => {
    setResearchType(value.value);
  };

  const handleChange = async (
    selected: SingleValue<{ value: string; label: string }>
  ) => {
    setResearcher({
      value: selected?.value ?? "ALL",
      label: selected?.label ?? "ทั้งหมด",
    });

    // setResearchOption(await props.chartStore.fetchStatistic(selected?.value ?? "ALL")); // <- uncomment this line to fetch data from server

    setResearchOption(
      props.chartStore.getStatisticByOption(selected?.value ?? "ALL")
    ); // <- comment this line to fetch data from server

    props.chartStore.researcher_selected = selected?.value ?? "ALL";
  };

  return (
    <AccessTokenProvider>
      <div className="min-h-screen flex flex-col items-center justify-center bg-yellow_theme p-10 overflow-hidden relative">
        <Navbar />
        <div className="flex py-5 gap-8 max-md:flex-col mt-5">
          <Selected
            handleChange={handleChange}
            placeholder="ชื่อนักวิจัย"
            data={props.chartStore.getResearcher}
            custom="w-[20vw] max-md:w-[60vw]"
            menuPortalZIndex={10}
            menu={10}
          />
          <Selected
            handleChange={handleChangeResearchType}
            placeholder="ชนิดของงานวิจัย"
            data={Research_type_option}
            custom="w-[37vw] max-md:w-[60vw]"
            menuPortalZIndex={10}
            menu={10}
          />
        </div>
        <div className="flex gap-10 max-md:flex-col z-10 max-md:justify-center max-md:items-center">
          {researcher.value === "ALL" ? (
            ""
          ) : (
            <ResearcherInfo chartStore={props.chartStore} />
          )}
          <ResearchChart
            chartStore={props.chartStore}
            chartType={researchType}
            researcher={researcher.label}
            researchOption={researchOption}
          />
        </div>
        <div className=" w-[20rem] h-[100vh] rotate-45 absolute bottom-[-50%] right-[-20%] bg-blue_gray_theme max-md:hidden"></div>
        <div className=" w-[20rem] h-[100vh] rotate-45 absolute top-[-40%] left-[-20%] bg-orange_theme max-md:hidden"></div>
      </div>
    </AccessTokenProvider>
  );
});

export default Chart;
