import axios from "axios";
import type { Research_Option } from "../../interfaces/ResearchOption";
import { BaseURL } from "../baseURl";

export interface FetchCountResearchOptionALLResponse {
  message: string;
  statistic: Research_Option;
  status: number;
}

export const FetchCountResearchOptionALL =
  async (): Promise<FetchCountResearchOptionALLResponse> => {
    const response: FetchCountResearchOptionALLResponse = await axios
      .get<FetchCountResearchOptionALLResponse>(
        `${BaseURL}/research/researcher/all`
      )
      .then((res) => {
        const status = res.status;

        return {
          status: status,
          statistic: res.data.statistic,
          message: res.data.message,
        };
      })
      .catch((err) => {
        const status = err.response.status;
        return {
          status,
          message: err.response.data.message,
          statistic: {} as Research_Option,
        };
      });

    return {
      message: response.message,
      statistic: response.statistic,
      status: response.status,
    };
  };
