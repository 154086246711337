interface QuartileType {
  value: string;
  label: string;
}

export const Quartile: QuartileType[] = [
  {
    value: "1",
    label: "Quartile 1",
  },
  {
    value: "2",
    label: "Quartile 2",
  },
  {
    value: "3",
    label: "Quartile 3",
  },
  {
    value: "4",
    label: "Quartile 4",
  },
];
