import { makeAutoObservable, configure, action } from "mobx";
import {
  FetchResearchBySelectResponse,
  FetchResearchBySelect,
  FetchResearchParameter,
} from "../api/researchTable/fetchResearchBySelect";
import { ResearchType } from "../interfaces/ResearchType";
import { Research } from "../mocks/research";

configure({
  enforceActions: "never",
});

export class ResearchStoreImpl {
  research_collection: FetchResearchBySelectResponse = Research;
  research_collection_pagination: ResearchType[] = [];
  research_search_option: string = "ALL";
  research_pagination_max_page: number =
    Math.floor(this.research_collection.research.length / 4) + 1;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  async fetchData({
    research_type,
    researcher_id,
    quartile,
  }: FetchResearchParameter) {
    const response = await FetchResearchBySelect({
      research_type,
      researcher_id,
      quartile,
    });

    this.research_collection = response;
    this.research_pagination_max_page =
      Math.floor(this.research_collection.research.length / 4) + 1;
    this.getResearchCollectionPagination(0);
  }

  @action
  get getResearchCollenctionOption() {
    const option = this.research_collection.research.map((item) => {
      return {
        value: item.research_id,
        label: item.title,
      };
    });
    return option;
  }

  @action // do when user click on paginate component
  getResearchCollectionPagination(curr: number) {
    if (this.research_search_option === "ALL") {
      this.research_collection_pagination =
        this.research_collection.research.slice(curr * 4, curr * 4 + 4);
    }
  }

  @action
  setResearchSearchOption(option: string) {
    this.research_search_option = option;
    if (option === "ALL") {
      this.research_collection_pagination =
        this.research_collection.research.slice(0, 4);
      this.research_pagination_max_page =
        Math.floor(this.research_collection.research.length / 4) + 1;
    } else {
      this.research_collection_pagination =
        this.research_collection.research.filter(
          (item) => item.research_id === option
        );
      this.research_pagination_max_page = 1;
    }
  }

  @action
  getResearchById(id: string) {
    return this.research_collection.research.filter(
      (item) => item.research_id === id
    )[0];
  }
}

export const ResearchStore = new ResearchStoreImpl();
