import React, { useState } from "react";
import SignIn from "../common/SignIn.common";
import TextField from "../common/TextField.common";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { LoginRouter } from "../api/authentication/login";
import { HttpStatus } from "../api/httpStatus";
import { regexEmail } from "../helpers/regexEmail";
import { WEBSITE_PATH } from "../config/Path";

interface FormData {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [closePassword, setClosePassword] = useState<boolean>(true);

  const [formData, setFormData] = useState<FormData>({
    email: "",
    password: "",
  });
  const [errorForm, setErrorForm] = useState<FormData>({
    email: "",
    password: "",
  });

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { email, password } = formData;
    setErrorForm({
      email: "",
      password: "",
    });

    if (!email || !regexEmail(email)) {
      setErrorForm((prev) => ({
        ...prev,
        email: "Email is not valid",
      }));
      return;
    }

    if (password.length < 6) {
      setErrorForm((prev) => ({
        ...prev,
        password: "Password must be at least 6 characters",
      }));
      return;
    }

    // TODO: login
    const response = await LoginRouter({
      email,
      password,
    });

    if (response.status === HttpStatus.UNAUTHORIZED) {
      setErrorForm({
        email: response.message,
        password: "",
      });
      return;
    } else if (response.status === HttpStatus.NOT_FOUND) {
      setErrorForm({
        password: "",
        email: response.message,
      });
      return;
    } else if (response.status === HttpStatus.OK) {
      navigate(WEBSITE_PATH.HOME);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <SignIn submit={onSubmit} title="Sign in">
      <div className="gap-3 flex flex-col">
        <TextField
          placeholder="email"
          name="email"
          onChange={handleChange}
          error={`${errorForm.email ? errorForm.email : ""}`}
          type="email"
          value={formData.email}
        />
        <div className="relative">
          <TextField
            placeholder="password"
            name="password"
            onChange={handleChange}
            error={`${errorForm.password ? errorForm.password : ""}`}
            type={closePassword ? "password" : "text"}
            maxLength={20}
            minLength={6}
            value={formData.password}
          />
          <div>
            {closePassword ? (
              <AiOutlineEye
                className="absolute right-2 top-3 cursor-pointer z-10 bg-white"
                size={20}
                onClick={() => setClosePassword(!closePassword)}
              />
            ) : (
              <AiOutlineEyeInvisible
                className="absolute right-2 top-3 cursor-pointer bg-white"
                onClick={() => setClosePassword(!closePassword)}
                size={20}
              />
            )}
          </div>
        </div>
      </div>
    </SignIn>
  );
};

export default Login;
