export interface ResearcherType {
  id: any;
  name: string;
  english_name: string;
  education: string[];
  research_area: string[];
  email?: string;
  tel?: string;
  image?: string;
  // research: ResearchType[];
}

export interface ResearchType {
  year: string;
  title: string;
  link: string;
}

export const RESEARCHER: ResearcherType[] = [
  {
    image: "/images/static/staff/acs/warinwat.jpg",
    name: "ดร.วรินทร์ วัฒนพรพรหม",
    english_name: "(Dr.Warin Wattanapornprom)",
    education: [
      "B.Sc.(Information Technology), Sirindhorn International Institute of Technology, Thammasat University, Thailand",
      "B.A.(Information Science) Sukhothai Thammatirat Open University, Thailand",
      "B.A.(Chinese for Economy and commerce), Beijing Language and Culture University ,Beijing, China",
      "M.Sc.(Computer Science), Chulalongkorn University, Thailand",
      "Ph.D.(Computer Engineering), Chulalongkorn University, Thailand",
    ],
    research_area: [
      "Multimodal and multi-objective optimization",
      "Multiclass and multilabel classification",
      "Bioinformatics and computational biology",
      "Production line scheduling and balancing",
      "Data driven marketing",
      "Metaheutistics",
    ],
    tel: "Tel: 02 470 8987",
    id: "71c2a2db-58cf-4616-892b-b486948953bd",
  },
  {
    image: "/images/static/staff/acs/chukiatwor.jpg",
    name: "รศ.ชูเกียรติ วรสุชีพ",
    english_name: "(Assoc. Prof. Chukiat Worasucheep)",
    education: [
      "B.Eng (Computer Engineering Hons.1), Chulalongkorn University, Thailand",
      "M.S.(Computer Science), Oregon State University, U.S.A. ",
      "MBA (Business Administration), Thammasat University, Thailand",
    ],
    research_area: [
      "Automatic financial trading algorithm",
      "Metaheuristics for modeling gene regulatory networks",
      "Optimization in finance and engineering",
      "Data Science",
    ],
    tel: "Tel: 02 470 8890",
    id: "8c1865b6-51ae-4dec-8f64-5c923dd521b3",
  },
  {
    image: "/images/static/staff/acs/pariwatevar.jpg",
    name: "ดร.ปริเวท วรรณโกวิท",
    english_name: "(Dr. Pariwate Varnakovida)",
    education: [
      "Ph.D.(Geography), Michigan State University, U.S.A. ",
      "M.S.(Information Technology), Mahidol University, Thailand",
      "B.S.(Mathematics) Chiang Mai University, Thailand",
    ],
    research_area: [
      "Geography",
      "Geographic Information System",
      "Water management",
      "Remote Sensing",
      "Computer application",
    ],
    id: "6eb0088c-2e8b-45a7-8cab-bda9da99dae4",
  },
  {
    image: "/images/static/staff/acs/wiboonsakwat.jpg",
    name: "รศ. ดร.วิบูลศักดิ์ วัฒายุ",
    english_name: "(Assoc. Prof. Dr.Wiboonsak Watthayu)",
    education: [
      "B.Sc.(Mathematics), King Mongkut's University of Technology Thonburi, Thailand",
      "M.Sc.(Computer Science), Asian Institute of Technology, Thailand",
      "M.Sc.(Computer Science), Old Dominion University, U.S.A.",
      "Ph.D.(Computer Science), University of Maryland, Baltimore, U.S.A",
    ],
    research_area: [
      "Data Science",
      "Big Data Analytics",
      "Applied Mathematics",
      "Mathematical Modelling in Environments",
      "Climate change",
      "Data Mining",
      "Machine Learning",
    ],
    tel: "Tel: 02 470 8821, 8924",
    id: "db791bb3-36e4-4121-a10a-c2327b34f5f9",
  },
  {
    image: "/images/static/staff/acs/wittawinsus.jpg",
    name: "ดร.วิธวินท์ สุสุทธิ",
    english_name: "(Dr.Wittawin Susutti)",
    education: [
      "B.Sc.(Mathematics) ,King Mongkut's University of Technology Thonburi, Thailand",
      "M.Sc.(Computer Science), Chulalongkorn University, Thailand",
      "Ph.D.(Computer Science), Chulalongkorn University, Thailand",
    ],
    research_area: [
      "Computer vision and image processing",
      "Machine learning",
      "Data science",
    ],
    id: "e35d2596-50bg-4693-92cc-5f1ebc7a158f",
  },
  {
    image: "/images/Lecturer/Com-sci/Dr_Thittaporn.png",
    name: "ดร.ฐิตาภรณ์​ กนกรัตน",
    english_name: "(Dr.Thittaporn Ganokratanaa)",
    education: [
      "2021 Ph.D. Electrical-Engineering, Chulalongkorn University",
      "2017 M.Eng. Electrical Engineering, Chulalongkorn University",
      "2015 B.Sc. Media Technology (First Class Honors), King Mongkut's University of Technology Thonburi",
    ],
    research_area: [
      "Image processing",
      "Multimedia signal processing",
      "Video analysis",
      "Anomaly detection",
      "Computer vision",
      "Machine learning",
      "Artificial intelligence",
      "Human-computer interaction",
    ],
    tel: "Tel: 02 470 8989",
    id: "415d2596-503d-4693-92cc-5f1ebc7a158f",
  },
  {
    image: "/images/static/staff/acs/suvilcho.jpg",
    name: "ดร.ศุวิล ชมชัยยา",
    english_name: "(Dr.Suvil Chomchaiya)",
    education: [
      "D.Sc. (Information Management), Asian Institute of Technology, Thailand M.S. (Computer Science), Governors State University, Illinois, U.S.A.",
      "M.Sc. (Business Administration), California University of Pennsylvania, Pennsylvania ,U.S.A.",
      "B.Eng.(Production Engineering),King Mongkut's University of Technology North Bangkok, Thailand",
    ],
    research_area: [
      "Automotive UI, Design of UI/UX and Interation",
      "Human-Computer Interaction",
      "Usability Engineering",
      "Performacne Measurement of Information System",
      "Data Visualization",
    ],
    tel: "Tel: 02 470 8995",
    id: "e35d25bg-503d-4693-92cc-5f1ebc7a158f",
  },
  {
    image: "/images/static/staff/mth/charoensin.jpg",
    name: "อ.เจริญ สินอภิรมย์สราญ",
    english_name: "(Lect. Charoen Sinaphironmsaran)",
    education: ["B.Sc.(Mathematics) ,Chulalongkorn university Thailand"],
    research_area: ["Real Analysis ", "Abstract Algebra"],
    id: "e35d25bf-503d-4693-92cc-5f1ebc7a158f",
  },
  {
    image: "/images/static/staff/mth/chatchawanwat.jpg",
    name: "ดร.ชัชวาลย์ วัชราเรืองวิทย์",
    english_name: "(Dr.Chatchawan Watchararuangwit)",
    education: [
      "B.Sc.(Mathematics),Chiang Mai University, Thailand",
      "M.Sc.(Mathematics) ,Mahidol university, Thailand",
      "Ph.D.(Applied Math.),Suranaree University of Technology, Thailand",
    ],
    research_area: ["Models and numerical solvers for image registration"],
    tel: "Tel: 02 470 8925",
    id: "e35d2596-50tb-4693-92cc-5f1ebc7a158f",
  },
  {
    image: "/images/static/staff/mth/natawatkla.jpg",
    name: "ดร.ณฐวัฒน์ กล่ำสกุล",
    english_name: "(Dr.Natawat Klamsakul)",
    education: [
      "B.S.(Mathematics) Chiang Mai University Thailand",
      "M.S.(Mathematics) University of Illinois at Urbana-Champaign, U.S.A",
      "Ph.D.(Mathematics) University of Illinois at Urbana-Champaign, U.S.A",
    ],
    research_area: ["Harmonic analysis", "Singular integral operators"],
    tel: "Tel: 02 470 8918",
    id: "e35d2596-503d-2493-92cc-5f1ebc7a158f",
  },
  {
    image: "/images/static/staff/mth/songponsri.jpg",
    name: "ผศ. ดร.ทรงพล ศรีวงค์ษา",
    english_name: "(Asst. Prof. Dr.Songpon Sriwongsa)",
    education: [
      "B.Sc. (Mathematics, Hons. 1), Chulalongkorn University, Thailand",
      "M.Sc. (Mathematics), Chulalongkorn university, Thailand",
      "Ph.D. (Mathematics), University of Wisconsin Milwaukee, U.S.A",
    ],
    research_area: [
      "Algebraic graphs over finite rings",
      "Applied algebras",
      "Dynamical systems over rings",
      "Evolution algebras over rings",
      "Representation of Lie algebras or Lie groups",
      "Structure of finite commutative rings",
    ],
    tel: "Tel: 02 470 8992",
    id: "e35d2596-503d-4693-92cc-5f1ebc7ahw8f",
  },
  {
    image: "/images/static/staff/mth/siriratkhu.jpg",
    name: "ดร.ธารีรัตน์ ธนัตถ์พาณิชย์",
    english_name: "(Dr.Tareerat Tanutpanit)",
    education: [
      "B.Sc.(Applied Math.),King Mongkut's Institute of Technology Ladkrabang, Thailand",
      "M.Sc.(Applied.Math.),King Mongkut's Institute of Technology Ladkrabang, Thailand",
      "Ph.D.(Applied.Math.),King Mongkut's Institute of Technology Ladkrabang, Thailand",
    ],
    research_area: [
      "Applied Mathematics Modeling",
      "Math Industry",
      "Mathematics Education",
    ],
    id: "e35d2596-503d-4693-92cc-5f1ebcht158f",
  },
  {
    image: "/images/static/staff/mth/thiradetjia.jpg",
    name: "รศ.ดร.ธีระเดช เจียรสุขสกุล",
    english_name: "(Assoc. Prof. Thiradet Jiarasuksakun)",
    education: [
      "Ph.D. (Mathematics), University of Michigan, Ann Arbor, U.S.A.",
      "M.S. (Mathematics), University of Michigan, Ann Arbor, U.S.A.",
      "B.A. (Economics), University of Chicago, U.S.A. ",
      "B.S. (Mathematics with Honors),University of Chicago, U.S.A.",
    ],
    research_area: [
      "Combinatorics and Graph TheoryResearch includes counting, permutation, combination, scheduling, tournament, graph coloring, coding theory and other related fields",
      "Math Education - teaching and learning, assessment",
    ],
    tel: "Tel: 02 470 9545, 8801",
    id: "e35d2596-503d-4693-92fs-5f1ebc7a158f",
  },
  {
    image: "/images/static/staff/mth/teerapolsal.jpg",
    name: "ผศ.ดร.ธีระพล สลีวงศ์",
    english_name: "(Asst. Prof.Dr.Teeapol Saleewong)",
    education: [
      "B.Sc.(Mathematics),Chiang Mai University, Thailand",
      "M.Sc.(Applied Math.),Chiang Mai University, Thailand",
      "Ph.D.(Biomedical Engineering),Chulalongkorn University, Thailand",
    ],
    research_area: [
      "Mathematical Modeling",
      "Mathematical Physiology",
      "Pharmacometrics",
      "Medical Mathematics",
      "Personalized Medicine",
      "Biomedical Engineering",
      "Mathematical Industry",
      "Optimization",
      "Mathematical for Educators",
    ],
    tel: "Tel: 02 470 8993",
    id: "e35d2596-503d-4693-92cc-5f1ebc7et58f",
  },
  {
    image: "/images/static/staff/mth/boobphachardcha.jpg",
    name: "อ.บุปผชาติ จันทร์สว่าง",
    english_name: "(Boobphachard Chansawang)",
    education: [
      "B.Ed.(Mathematics),Ubon Ratchathani Rajabhat University, Thailand",
      "M.Sc.(Applied Math.),King Mongkut's University of Technology Thonburi, Thailand",
    ],
    research_area: [
      "Applied Mathematics and Modeling",
      "Acturial Science",
      "Differential Equations",
    ],
    tel: "Tel: 02 470 8824",
    id: "e35d2596-503d-4693-92cc-5f1ebc7a1sr8f",
  },
  {
    image: "/images/static/staff/mth/parinyasan.jpg",
    name: "ผศ.ดร.ปริญญา เสงี่ยมสุนทร",
    english_name: "(Asst. Prof. Dr. Parinya Sa Ngiamsunthorn)",
    education: [
      "B.Sc. (Mathematics, Hons 1), The University of Sydney, Australia",
      "Ph.D. (Mathematics), The University of Sydney, Australia",
    ],
    research_area: [
      "Nonlinear analysis",
      "Partial differential equations and dynamical systems",
      "Fracttional Calculus",
      "Fractional Differential Equation",
    ],
    tel: "Tel: 02 470 8828",
    id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
  },
  {
    image: "/images/static/staff/mth/parincha.jpg",
    name: "ดร.ปรินทร์ ไชยปัญญา",
    english_name: "(Dr.Chaipunya)",
    education: [
      "B.Sc.(Mathematics, Hons. 1),King Mongkut's University of Technology Thonburi, Thailand",
      "Ph.D.(Applied Math.),King Mongkut's University of Technology Thonburi ,Thailand",
    ],
    research_area: [
      "Alexandrov geometry",
      "Geometric analysis",
      "Geometric measure theory",
      "Optimal mass transportation",
      "Variational analysis and optimization",
    ],
    tel: "Tel: 02 470 8985",
    id: "e35d2596-503d-4693-92cc-5f1ebc7a158q",
  },
  {
    image: "/images/static/staff/mth/pawatonkae.jpg",
    name: "รศ. ดร.ภวธน เขมะวิชานุรัตน์",
    english_name: "(Assoc. Prof. Dr.Pawaton Kaemawichanurat)",
    education: [
      "B.Sc.(Mathematics) Silpakorn University ,Thailand",
      "M.Sc.(Mathematics) Silpakorn University,Thailand",
      "Ph.D. (Mathematics), Curtin University, Australia",
    ],
    research_area: [
      "Graph Theory- Domination- Hamiltonian- Planar Graphs",
      "Combinatorics - Finite Set Theory",
      "Computational Geometry- Triangulated Polygon- Art Gallery Problem",
    ],
    tel: "Tel: 02 470 9547",
    id: "e35d2596-503d-4693-92cc-5f1ebc7a1585",
  },
  {
    image: "/images/static/staff/mth/ipooumam.jpg",
    name: "ศ.ดร.ภูมิ คำเอม",
    english_name: "(Prof. Poom Kumam)",
    education: [
      "B.Ed.(Mathematics), Burapha University, Thailand",
      "M.Sc.(Mathematics), Chiang Mai University Thailand",
      "Ph.D.(Mathematics), Naresuan University Thailand",
    ],
    research_area: [
      "Existance Theorems and Related Behaviors",
      "Set-Valued Analysis",
      "Optimization, Variational Inequality and Equilibrium Theory",
      "Approximation and Solution Methods",
      "Fixed-point theory",
      "Variational analysis",
      "Random operator theory",
      "Fractional differential equations",
      "Differential game",
      "Entropy and Quantum operators",
      "Fuzzy soft set",
      "Mathematical modeling for fluid dynamics and areas of interest Inverse problems",
      "Dynamic games in economics",
      "Image restoration",
      "Signal and image processing",
      "Game Theory and Cryptology",
    ],
    id: "e35d2596-503d-4693-92cc-5f1ebc7a15asd",
  },
  {
    image: "/images/static/staff/mth/ratchanikorncho.jpg",
    name: "ดร.รัชนิกร ชลไชยะ",
    english_name: "(Dr. Ratchanikorn Chonchaiya)",
    education: [
      "B.Sc. Mathematics (Second Class Honors),Chulalongkorn University, Thailand",
      "M.Sc. Mathematics, Chulalongkorn University, Thailand",
      "M. Sc. Mathematics Education, Ramkhamhaeng University, Thailand",
      "Ph.D. Mathematics, University of Reading, United Kingdom",
    ],
    research_area: [
      "Probability Theory",
      "Mathematics Learning",
      "Management e.g. Active Learning, Flipped Classroom",
      "Mathematical Resilience",
      "Teacher Education",
      "Higher Education",
      "Vocational Education",
    ],
    id: "e35d2596-503d-4693-92cc-5f1ebc7a158t",
  },
  {
    image: "/images/static/staff/mth/warapornchat.jpg",
    name: "ผศ.ดร.วราภรณ์ จาตนิล",
    english_name: "(Asst. Prof. Dr. Waraporn Chatanin)",
    education: [
      "B.Sc.(Mathematics) ,Silpakorn University, Thailand",
      "M.Sc.(Applied Math.),King Mongkut's University of Technology Thonburi, Thailand",
      "Ph.D.(Applied Math.) ,King Mongkut's University of Technology Thonburi, Thailand",
    ],
    research_area: [
      "Applied Mathematics- Lie group Theory - Exact solutions to differential equations- Conditional linearization- Stability Analysis",
      "Mathematics Education- Teaching methods and classroom techniques- Teaching and learning processes",
    ],
    tel: "Tel: 02 470 9548",
    id: "e35d2596-503d-4693-92cc-5f1ebc7a158w",
  },
  {
    image: "/images/static/staff/mth/warisayom.jpg",
    name: "ผศ. ดร.วริสา ยมเสถียรกุล",
    english_name: "(Asst. Prof. Dr. Warisa Yomsatieankul)",
    education: [
      "B.Sc.(Mathematics) ,Mahidol University, Thailand",
      "M.Sc.(Applied Math.), King Mongkut's University of Technology Thonburi, Thailand",
      "Dr.rer.nat. (Mathematics), Naturwissenschaften, Technische Universität Braunschweig, Germany",
    ],
    research_area: [
      "Numerical Methods and Analysis",
      "Mathematical modeling",
      "Applied Mathematics in Industry",
    ],
    tel: "Tel: 02 470 9543",
    id: "e35d2596-503d-4693-92cc-5f1ebc7a158a",
  },
  {
    image: "/images/static/staff/mth/wirawonchi.jpg",
    name: "รศ.ดร.วิราวรรณ ชินวิริยสิทธิ์",
    english_name: "(Assoc. Prof. Dr. Wirawan Chinviriyasit)",
    education: [
      "B.Sc.(Math. Education) ,Prince of Songkla University, Thailand",
      "M.Sc.(Applied Math.),King Mongkut's University of Technology Thonburi ,Thailand",
      "Ph.D. (Applied Math.),Brunel University, U.K.",
    ],
    research_area: [
      "Mathematical models in epidemiology",
      "Numerical modeling of differential equations",
      "Dynamical system of wastewater treatment processes",
      "Mathematical education",
    ],
    tel: "Tel: 02 470 8833",
    id: "e35d2596-503d-4693-92cc-5f1ebc7a158d",
  },
  {
    image: "/images/static/staff/mth/sumlearngchu.jpg",
    name: "ผศ.ดร.สำเริง ชื่นรังสิกุล",
    english_name: "(Asst. Prof. Dr. Sumlearng Chunrungsikul)",
    education: [
      "B.Sc.(Mathematics),Ramkhamhaeng University, Thailand",
      "M.Sc. (Applied Math.), Imperial College London, U.K.",
      "Ph.D. (Mathematics), Brunel University, U.K.",
    ],
    research_area: ["Mathematical Biology", "Dynamical Systems"],
    tel: "Tel: 02 470 8984",
    id: "e35d2596-503d-4693-92cc-5f1ebc7a158f",
  },
  {
    image: "/images/static/staff/mth/settapatchi.jpg",
    name: "รศ.ดร.เศรษฐภัทร ชินวิริยสิทธิ์",
    english_name: "(Assoc. Prof. Dr. Settapat Chinviriyasit)",
    education: [
      "B.Sc.(Math. Education) ,Prince of Songkla University, Thailand",
      "M.Sc.(Applied Math.) ,King Mongkut's University of Technology Thonburi, Thailand",
      "Ph.D. (Applied Math.), Brunel University, U.K.",
    ],
    research_area: [
      "Mathematical models in epidemiology",
      "Numerical modeling of differential equations",
    ],
    tel: "Tel: 02 470 8830",
    id: "54c527b2-e76d-412e-91c3-2ca0ef113ac1",
  },
  {
    image: "/images/static/staff/mth/anirutlua.jpg",
    name: "รศ.ดร.อนิรุธ ลวดทรง",
    english_name: "(Assoc. Prof. Dr. Anirut Luadsong)",
    education: [
      "B.Sc. (Mathematics), Ramkhamhaeng University, Thailand",
      "M.Sc.(Applied.Math.) ,King Mongkut's University of Technology Thonburi, Thailand",
      "Ph.D.(Applied Math.),Suranaree University of Technology, Thailand",
    ],
    research_area: [
      "Engineering, Applied and Computational Mathematics",
      "Computational Fluid Dynamics",
      "Fractional Differential Equations",
    ],
    tel: "Tel: 02 470 8837",
    id: "ee37ee1e-140e-49e1-83c0-a477ff50a84d",
  },
  {
    image: "/images/static/staff/mth/usawan.jpg",
    name: "รศ.ดร.อุษา ฮัมฟรี่",
    english_name: "(Assoc. Prof. Usa Humphries)",
    education: [
      "B.Sc.(Math. Education), Prince of Songkla University Thailand",
      "M.Sc.(Applied. Mathematics),King Mongkut's University of Technology Thonburi ,Thailand",
      "Ph.D.(Applied Mathematics),University of Exeter, UK)",
    ],
    research_area: [
      "Numerical oceanic and Atmospheric modeling",
      "Numerical methods and Analysis",
      "Weather and Climate change",
      "Data assimilation and Data analysis",
    ],
    tel: "Tel: 02 470 8831",
    id: "3e8cff2d-03ed-454e-b02a-7fc86f6dc334",
  },
  {
    image: "/images/static/staff/mth/mth_1.jpg",
    name: "Dr. Saeid Zahmatkesh Komeleh",
    english_name: "(Asst. Prof. Dr. Saeid Zahmatkesh Komeleh)",
    education: [
      "B.Sc.(Applied Mathematics), Payam Noor University, Ghazvin, Iran",
      "M.Sc.(Pure Mathematics), Sahand University of Technology, Tabriz, Iran",
      "Ph.D.(Mathematics), University of Science Malaysia.",
    ],
    research_area: ["Operator Algebras and Functional Analysis"],
    tel: "Tel: 02 470 9549",
    id: "ff47a199-c1a2-408b-871a-708e059a4fda",
  },
  {
    image: "/images/static/staff/executive/angkoolwan.jpg",
    name: "ดร.อังกูร หวังวงศ์ชัย",
    english_name: "(Dr. Angkool Wangwongchai)",
    education: [
      "B.Sc.(Mathematics) ,King Mongkut's University of Technology Thonburi, Thailand",
      "M.Sc.(Applied Math.) ,King Mongkut's University of Technology Thonburi, Thailand",
      "Ph.D. (Meteorology), Institute of Atmospheric Physics, Chinese Academy of Sciences, China",
    ],
    research_area: [
      "Numerical oceanic and Atmospheric modeling",
      "Numerical methods and Analysis",
    ],
    tel: "Tel: 02 470 8825",
    id: "829c7d60-e53b-4c28-95fb-5663a072adf0",
  },
  {
    image: "/images/static/staff/sta/chunchompon.jpg",
    name: "ผศ.ดร.ชื่นชม ศาลิคุปต",
    english_name: "(Asst. Prof. Dr.Chunchom Salikupata)",
    education: [
      "B.Sc.(Mathematics) ,King Mongkut's University of Technology Thonburi, Thailand",
      "M.Sc.(Applied Math.) ,King Mongkut's University of Technology Thonburi, Thailand",
      "Ph.D.(Applied Math.) ,King Mongkut's University of Technology Thonburi, Thailand",
    ],
    research_area: ["Applied statistics"],
    tel: "Tel: 02 470 9584",
    id: "2074a939-1f7f-4989-b51a-b6fe519523db",
  },
  {
    image: "/images/static/staff/sta/nathakhunwir.jpg",
    name: "ผศ. ดร.ณรรฐคุณ วิรุฬห์ศรี",
    english_name: "(Asst. Prof. Dr.Nathakhun Wiroonsri)",
    education: [
      "Bacherlor of Science in Mathematics from Chulalongkorn university",
      "Master of Financial Mathematics from North Carolina State University USA",
      "Doctor of philosophy in applied Mathematics from University of Southern California ,USA",
    ],
    research_area: [
      "Distributional approximation via Stein's method",
      "Applied probability",
      "Pattern recognition",
      "Machine learning",
      "Statistical learning in industry and social science",
    ],
    tel: "Tel: 02 470 8826",
    id: "e2a393dd-232c-49e5-9e50-eda45714b8d4",
  },
  {
    image: "/images/static/staff/sta/daowsag.jpg",
    name: "ผศ.ดาว สงวนรังศิริกุล",
    english_name: "(Asst. Prof.Daow sanguanrungsirikul)",
    education: [
      "B.Sc.(Statistics),Khon Kaen University, Thailand",
      "M.Sc.(Statistics),Chulalongkorn University, Thailand",
    ],
    research_area: ["Applied statistics"],
    tel: "Tel: 02 470 8986",
    id: "e4aee80d-232b-496e-93af-18a1b081aff5",
  },
  {
    image: "/images/static/staff/sta/dawudtho.jpg",
    name: "ผศ.ดร.ดาวุด ทองทา",
    english_name: "(Asst. Prof. Dawud Thongtha)",
    education: [
      "B.Sc.(Mathematics),Chulalongkorn University, Thailand",
      "M.Sc.(Mathematics),Chulalongkorn University, Thailand",
      "Ph.D.(Mathematics),Chulalongkorn University, Thailand",
    ],
    research_area: ["An Analytical Approximation of Option Prices"],
    tel: "Tel: 02 470 8996",
    id: "835fc675-b551-4aa9-9d47-fe355f0d1653",
  },
  {
    image: "/images/static/staff/sta/thanetchi.jpg",
    name: "อ.ธเนศ จิตต์สุภาพรรณ",
    english_name: "(Thanet Chitsuphaphan)",
    education: [
      "B.Sc.(Mathematics),Kasetsart University, Thailand",
      "M.Sc.(Applied Statistics&IT), National Institute of Development Administration, Thailand",
    ],
    research_area: [
      "Statistical forecasting",
      "Stochastic programming ",
      "Applied statistics",
    ],
    tel: "Tel: 02 470 8997",
    id: "37420d4e-83a6-49d8-af60-d3cac5a3481b",
  },
  {
    image: "/images/static/staff/sta/porntipdec.jpg",
    name: "ดร.พรทิพย์ เดชพิชัย",
    english_name: "(Dr. Porntip Dechpichai)",
    education: [
      "B.Sc. (Statistics), Srinakharinwirot University, Songkla, Thailand",
      "M.Sc. (Statistics), Kasetsart University, Thailand",
      "Ph.D. (Statistics), University of Wollonghong, Australia",
    ],
    research_area: [
      "Statistical downscaling models (SDMs)",
      "Categorical data analysis",
      "Applied statistics",
      "Data Science",
    ],
    tel: "Tel: 02 470 9544",
    id: "056a8764-dbb5-4583-859f-dac2933e0bec",
  },
  {
    image: "/images/static/staff/sta/wiwatsak.jpg",
    name: "อ.วิวัฒน์ สกลสนธิเศรษฐ์",
    english_name: "(Wiwat Sakonsontiset)",
    education: [
      "B.Sc.(Statistics) ,Ramkhamhaeng University, Thailand",
      "M.Sc.(Applied Statistics) ,Chulalongkorn University, Thailand",
    ],
    research_area: ["Operation Reseasch", "Data Sciences"],
    tel: "Tel: 02 470 8827",
    id: "6f94e743-deb4-4199-8956-290be3a7b212",
  },
  {
    image: "/images/static/staff/sta/sukuman.jpg",
    name: "ผศ.ดร.สุขุมาล สาริกะวณิช",
    english_name: "(Asst. Prof. Dr. Sukuman Sarikavanij)",
    education: [
      "B.Sc.(Mathematics) ,Mahidol University, Thailand",
      "M.Sc.(Applied Math.) ,Mahidol University, Thailand",
      "Ph.D.(Mathematics) ,Mahidol University, Thailand",
    ],
    research_area: [
      "Statistical Model",
      "Ranked Set Sampling",
      "Data Analysis",
    ],
    tel: "Tel: 02 470 9546",
    id: "20c46a2e-1549-4ef5-906c-4cb92ff2d91c",
  },
  {
    image: "/images/static/staff/mth/anuwatsae.jpg",
    name: "ดร.อนุวัฒน์ ตั้งธนวัฒน์สกุล",
    english_name: "(Dr. Anuwat Tangthanawatsakul)",
    education: [
      "B.Sc.(Mathematics), Chulalongkorn University, Thailand",
      "M.Sc. (Mathematics), Louisiana State University, U.S.A.",
      "Ph.D. (Mathematics), Louisiana State University, U.S.A.",
    ],
    research_area: [
      "Probability Theory and Statistics",
      "Stochastic Analysis and applications",
      "Stochastic Calculus (Ito theory) and Stochastic Differential Equations",
      "Probabilistic and Stochastic Models and related topics",
      "Time Series Analysis",
    ],
    tel: "Tel: 02 470 8988",
    id: "d91405ce-7a8e-4125-a83d-10f48db37f39",
  },
];
