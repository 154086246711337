import React from 'react';
import { useState } from "react";
import { Link } from "react-router-dom";
import '../../styles/layouts.css';
import '../../styles/sidebar.css';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export interface props {
    children?: React.ReactNode; 
}


const Layout = ({ children }: props) => {
    const [isActive, setIsActive] = useState(false);
    return (
        <>
            <div className={"sidebar drop-shadow fixed h-screen py-10 px-7 z-10 bg-white" + (isActive ?  " show" : "")}>
                <div className="sidebar-header flex py-3">
                    <div className="mr-auto">
                        <p className='font-bold'>Admin Dashboard</p>
                    </div>
                    <div>
                        <button className="sidebar-btn mb-0" onClick={() => {
                                setIsActive(!isActive);
                            }}>
                            <FontAwesomeIcon icon={faBars} size="lg"/>
                        </button>
                    </div>
                </div>
                <hr
                    style={{
                        background: '#293a74',
                        color: '#293a74',
                        borderColor: '#293a74',
                    }}
                />
                <div className="sidebar-body pt-3">
                    <div className='sidebar-section mb-5'>
                        <Link to="/">
                            <div className="sidebar-link">
                                ภาพรวม
                            </div>
                        </Link>
                    </div>
                    <div className='sidebar-section mb-5'>
                        <div className='sidebar-head text-black mb-1'>
                            จัดการข้อมูลรายงาน
                        </div>
                        <Link to="/edit/research">
                            <div className="sidebar-link">
                                จัดการข้อมูลรายงาน
                            </div>
                        </Link>
                        <Link to="/add/research">
                            <div className="sidebar-link">
                                เพิ่มข้อมูลรายงาน
                            </div>
                        </Link>
                    </div>
                    <div className='sidebar-section mb-5'>
                        <div className='sidebar-head text-black mb-1'>
                            จัดการชื่ออาจารย์
                        </div>
                        <Link to="/edit/researcher">
                            <div className="sidebar-link">
                                จัดการชื่ออาจารย์
                            </div>
                        </Link>
                        <Link to="/add/researcher">
                            <div className="sidebar-link">
                                เพิ่มชื่ออาจารย์
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='main p-5 md:p-10'>
                <nav className='flex drop-shadow rounded-md py-3 px-5 md:px-10 bg-white mb-8'>
                    <div className="nav-item mr-auto">
                        <button className="sidebar-btn mb-0" onClick={() => {
                                setIsActive(!isActive);
                            }}>
                            <FontAwesomeIcon icon={faBars} size="lg"/>
                        </button>
                    </div>
                    <div className="nav-item">
                        ยินดีต้อนรับกลับ
                    </div>
                </nav>
                <div className='section'>
                    {children}
                </div>
            </div>
        </>
       
    ); };

export default Layout;
