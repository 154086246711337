import React from 'react';
import Layout from "../../../components/layouts/layout";
import { useParams } from 'react-router-dom';

export const Edit = () => {
	let { DocId } = useParams();
	return (
		<Layout>
            <p className="mb-3 text-xl font-bold">แก้ไขข้อมูลรายงาน - { DocId }</p>
			<div className='bg-white p-3 drop-shadow rounded-md'>
                
            </div>
		</Layout>
	);
};

export default Edit;