import React from "react";

const ChartLayout: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <div className="h-[70vh] w-[60vw] flex bg-white p-5 rounded-md shadow-lg max-sm:h-[50vh] max-sm:w-[80vw]">
      {props.children}
    </div>
  );
};

export default ChartLayout;
