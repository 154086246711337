import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

interface ResearcherPersonalDataProps {
  name?: string;
  english_name?: string;
  email?: string;
  phone?: string;
  image?: string;
  education?: string[];
  research_area?: string[];
}

const ResearcherPersonalData: React.FC<ResearcherPersonalDataProps> = (
  props
) => {
  return (
    <div className="w-[60vw] h-[70vh] bg-white relative flex p-10 rounded-xl max-md:flex-col z-[10]">
      <div className="h-full flex justify-center">
        <div className="flex items-center max-md:w-[200px] max-md:h-[400px]">
          <LazyLoadImage
            src={`https://math.kmutt.ac.th${props?.image}`}
            loading="lazy"
            effect="blur"
            className="w-[440px] h-[400px]"
          />
        </div>
      </div>
      <div className="w-full px-5 py-2 overflow-scroll xl:px-10">
        <div className="flex justify-center flex-col pb-5 xl:gap-y-[0.5vw] ">
          <strong className="text-lg text-center xl:text-[1vw]">
            {props.name}
          </strong>
          <p className="text-sm text-center xl:text-[1vw]">
            {props.english_name}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <ul className="flex flex-col">
            <div className="mb-2 xl:text-[1vw]">
              <strong>Education</strong>
            </div>
            {props.education?.map((item, index) => {
              return (
                <li
                  key={index}
                  className="ml-5 text-[12px] flex items-center text-slate-500 xl:text-[0.9vw]"
                >
                  &bull; &nbsp;{item}
                </li>
              );
            })}
          </ul>
          <ul className="flex flex-col">
            <div className="mb-2 xl:text-[1vw]">
              <strong>Research Area</strong>
            </div>
            {props.research_area?.map((item, index) => {
              return (
                <li
                  key={index}
                  className="ml-5 text-[12px] flex items-center text-slate-500 xl:text-[0.9vw]"
                >
                  &bull; &nbsp;{item}
                </li>
              );
            })}
          </ul>
          {props.phone && (
            <ul className="flex flex-col">
              <div className="mb-2 xl:text-[1vw]">
                <strong>Contact</strong>
              </div>
              <div>
                <p className="ml-5 text-[12px] flex items-center text-slate-500 xl:text-[0.9vw]">
                  {props.phone}
                </p>
                <p className="ml-5 text-[12px] flex items-center text-slate-500 xl:text-[0.9vw]">
                  {props.email}
                </p>
              </div>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResearcherPersonalData;
