import React, { useState } from "react";
import kmuttLogo from "../assets/images/kmutt-logo.png";
import { useNavigate } from "react-router-dom";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import OutsideAlerter from "../hooks/useOutsideAlert";
import { WEBSITE_PATH } from "../config/Path";

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const handleClickOutside = () => {
    setShowMenu(false);
  };

  return (
    <div className="fixed z-[999] h-[7vh] bg-[#353A40] top-0 left-0 w-full shadow-lg flex items-center px-[5vw] py-8 border-b-2 border-orange_theme">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          <img
            src={kmuttLogo}
            alt=""
            className="w-[4rem] h-[4.5rem] cursor-pointer"
            onClick={() => navigate(WEBSITE_PATH.HOME)}
          />
          <p
            className="flex flex-col mt-2 hover:underline cursor-pointer decoration-orange_theme max-sm:hidden"
            onClick={() => navigate(WEBSITE_PATH.HOME)}
          >
            <span className="text-white text-sm">
              ภาควิชาคณิตศาสตร์ คณะวิทยาศาสตร์
              มหาวิทยาลัยเทคโนโลยีพระจอมเกล้าธนบุรี
            </span>
            <span className="text-white text-sm max-md:hidden max-lg:hidden">
              Department of Mathematics, Faculty of Science, King Mongkut's
              University of Technology Thonburi
            </span>
          </p>
        </div>
        <div className="relative inline-block text-left">
          <div className="flex gap-x-1">
            <OutsideAlerter action={handleClickOutside}>
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue_gray_theme hover:text-orange_theme duration-150 ease-in-out gap-x-1"
                onClick={() => setShowMenu(!showMenu)}
              >
                Pages
                {showMenu ? (
                  <RiArrowDropUpLine className="m-auto" color="white" />
                ) : (
                  <RiArrowDropDownLine className="m-auto" color="white" />
                )}
              </button>
              {showMenu && (
                <div
                  className={`absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
                    showMenu
                      ? "transition ease-out duration-100 animate-dropdownEnter"
                      : "transition ease-in duration-75 animate-dropdownLeave"
                  }`}
                >
                  <div className="py-1" role="none">
                    <p
                      className="text-gray-700 block px-4 py-2 text-sm hover:bg-slate-100 cursor-pointer"
                      onClick={() => navigate(WEBSITE_PATH.STATISTIC)}
                    >
                      สถิติงานวิจัย
                    </p>
                    <p
                      className="text-gray-700 block px-4 py-2 text-sm hover:bg-slate-100 cursor-pointer"
                      onClick={() => navigate(WEBSITE_PATH.RESEARCH)}
                    >
                      งานวิจัยของภาควิชา
                    </p>
                    <p
                      className="text-gray-700 block px-4 py-2 text-sm hover:bg-slate-100 cursor-pointer"
                      onClick={() => navigate(WEBSITE_PATH.LOGIN)}
                    >
                      เข้าสู่ระบบ
                    </p>
                  </div>
                </div>
              )}
            </OutsideAlerter>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
