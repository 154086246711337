import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import "../../styles/Pagination.css";
import { PaginationControl } from "../../helpers/paginateControl";
import { observer } from "mobx-react";
import { ResearchStoreImpl } from "../../store/ResearchStore";

interface PaginationProps {
  researchStore: ResearchStoreImpl;
}

const Pagination: React.FC<PaginationProps> = observer((props) => {
  const [currPage, setCurrPage] = useState<number>(1);
  const [maxPage, setMaxPage] = useState<number>(1);

  useEffect(() => {
    props.researchStore.getResearchCollectionPagination(0);
  }, [props.researchStore, props.researchStore.research_collection]);

  useEffect(() => {
    setMaxPage(props.researchStore.research_pagination_max_page);
  }, [
    props.researchStore.research_search_option,
    props.researchStore,
    props.researchStore.research_collection,
  ]);

  const handlePageClick = (data: { selected: number }) => {
    setCurrPage(data.selected + 1);
    props.researchStore.getResearchCollectionPagination(data.selected);
  };

  return (
    <div className="box">
      <ReactPaginate
        pageRangeDisplayed={PaginationControl(currPage, maxPage)}
        marginPagesDisplayed={1}
        pageCount={maxPage}
        previousLabel={
          <MdOutlineNavigateBefore color={currPage === 1 ? "gray" : "black"} />
        }
        nextLabel={
          <MdOutlineNavigateNext
            color={currPage === maxPage ? "gray" : "black"}
          />
        }
        breakLabel="..."
        onPageChange={handlePageClick}
        containerClassName="paginate"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousLinkClassName="prev-link"
        nextLinkClassName="next-link"
      />
    </div>
  );
});

export default Pagination;
