const nYearBack = (n: number): number[] => {
  var nYear: number[] = [];
  for (var i = 0; i < n; i++) {
    nYear.push(new Date().getFullYear() - n + i + 1);
  }

  return nYear;
};

export default nYearBack;
