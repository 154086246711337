import React from "react";

interface CarouselItemProps {
  image: string;
}

const CarouselItem: React.FC<CarouselItemProps> = (props) => {
  return (
    <div
      className="w-full h-[25rem] bg-yellow_theme flex items-center justify-center"
      style={{
        backgroundImage: `url(${props.image})`,
        objectFit: "cover",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>
  );
};

export default CarouselItem;
