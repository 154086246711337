import type { Research_Option } from "../interfaces/ResearchOption";

export type Statistic_ResearcherType = Research_Option & {
  researcher_id?: string;
};

export const Statistic_Researcher: Statistic_ResearcherType[] = [
  {
    researcher_id: "e35d2596-50tb-4693-92cc-5f1ebc7a158f", //ดร.ชัชวาลย์ วัชราเรืองวิทย์
    option_1: [1, 1, 0, 0, 0],
    option_2: [0, 0, 0, 0, 0],
    option_3: [0, 0, 0, 0, 0],
    option_4: [0, 0, 0, 0, 0],
    option_5: [0, 0, 0, 0, 0],
    option_6: [0, 0, 0, 0, 1],
    option_7: [0, 0, 0, 0, 0],
    option_8: [0, 0, 0, 0, 0],
    option_9: [0, 0, 0, 0, 0],
    option_10: [0, 0, 0, 0, 0],
    option_11: [0, 0, 0, 0, 0],
    option_12: [0, 0, 0, 0, 0],
    option_13: [0, 0, 0, 0, 0],
    option_14: [0, 0, 0, 0, 0],
    option_15: [0, 0, 0, 0, 0],
    option_16: [0, 0, 0, 0, 0],
    option_17: [0, 0, 0, 0, 0],
    option_18: [0, 0, 0, 0, 0],
    option_19: [0, 0, 0, 0, 0],
  },
  {
    researcher_id: "8c1865b6-51ae-4dec-8f64-5c923dd521b3", // รศ.ชูเกียรติ วรสุชีพ
    option_1: [0, 0, 0, 0, 0],
    option_2: [0, 1, 0, 0, 0], // ไม่รู้ปี
    option_3: [0, 0, 0, 0, 0],
    option_4: [0, 0, 0, 0, 0],
    option_5: [0, 0, 0, 0, 0],
    option_6: [0, 1, 0, 1, 0],
    option_7: [0, 0, 0, 0, 0],
    option_8: [0, 0, 0, 0, 0],
    option_9: [0, 0, 0, 0, 0],
    option_10: [0, 0, 0, 0, 0],
    option_11: [0, 0, 0, 0, 0],
    option_12: [0, 0, 0, 0, 0],
    option_13: [0, 0, 0, 0, 0],
    option_14: [0, 0, 0, 0, 0],
    option_15: [0, 0, 0, 0, 0],
    option_16: [0, 0, 0, 0, 0],
    option_17: [0, 0, 0, 0, 0],
    option_18: [0, 0, 0, 0, 0],
    option_19: [0, 0, 0, 0, 0],
  },
  {
    researcher_id: "415d2596-503d-4693-92cc-5f1ebc7a158f", // ดร.ฐิตาภรณ์​ กนกรัตน
    option_1: [0, 0, 0, 0, 0],
    option_2: [0, 0, 0, 3, 0],
    option_3: [0, 0, 0, 0, 0],
    option_4: [0, 0, 0, 0, 0],
    option_5: [0, 0, 0, 0, 0],
    option_6: [0, 0, 0, 0, 0],
    option_7: [0, 0, 0, 0, 0],
    option_8: [0, 0, 0, 0, 0],
    option_9: [0, 0, 0, 0, 0],
    option_10: [0, 0, 0, 0, 0],
    option_11: [0, 0, 0, 0, 0],
    option_12: [0, 0, 0, 0, 0],
    option_13: [0, 0, 0, 0, 0],
    option_14: [0, 0, 0, 0, 0],
    option_15: [0, 0, 0, 0, 0],
    option_16: [0, 0, 0, 0, 0],
    option_17: [0, 0, 0, 0, 0],
    option_18: [0, 0, 0, 0, 0],
    option_19: [0, 0, 0, 0, 0],
  },
  {
    researcher_id: "e35d2596-503d-2493-92cc-5f1ebc7a158f", // ดร.ณฐวัฒน์ กล่ำสกุล
    option_1: [0, 0, 0, 0, 0],
    option_2: [0, 0, 1, 1, 0],
    option_3: [0, 0, 0, 0, 0],
    option_4: [0, 0, 0, 0, 0],
    option_5: [0, 0, 0, 0, 0],
    option_6: [0, 1, 0, 0, 0],
    option_7: [0, 0, 0, 0, 0],
    option_8: [0, 0, 0, 0, 0],
    option_9: [0, 0, 0, 0, 0],
    option_10: [0, 0, 0, 0, 0],
    option_11: [0, 0, 0, 0, 0],
    option_12: [0, 0, 0, 0, 0],
    option_13: [0, 0, 0, 0, 0],
    option_14: [0, 0, 0, 0, 0],
    option_15: [0, 0, 0, 0, 0],
    option_16: [0, 0, 0, 0, 0],
    option_17: [0, 0, 0, 0, 0],
    option_18: [0, 0, 0, 0, 0],
    option_19: [0, 0, 0, 0, 0],
  },
  {
    researcher_id: "e2a393dd-232c-49e5-9e50-eda45714b8d4", // ผศ. ดร.ณรรฐคุณ วิรุฬห์ศรี
    option_1: [0, 0, 0, 0, 0],
    option_2: [0, 0, 0, 0, 0],
    option_3: [0, 0, 0, 0, 0],
    option_4: [0, 0, 0, 0, 0],
    option_5: [0, 0, 0, 0, 0],
    option_6: [2, 1, 0, 0, 0],
    option_7: [0, 0, 0, 0, 0],
    option_8: [0, 0, 0, 0, 0],
    option_9: [0, 0, 0, 0, 0],
    option_10: [0, 0, 0, 0, 0],
    option_11: [0, 0, 0, 0, 0],
    option_12: [0, 0, 0, 0, 0],
    option_13: [0, 0, 0, 0, 0],
    option_14: [0, 0, 0, 0, 0],
    option_15: [0, 0, 0, 0, 0],
    option_16: [0, 0, 0, 0, 0],
    option_17: [0, 0, 0, 0, 0],
    option_18: [0, 0, 0, 0, 0],
    option_19: [0, 0, 0, 0, 0],
  },
  {
    researcher_id: "835fc675-b551-4aa9-9d47-fe355f0d1653", // ผศ.ดร.ดาวุด ทองทา
    option_1: [0, 0, 0, 0, 0],
    option_2: [0, 0, 0, 0, 0],
    option_3: [0, 0, 0, 0, 0],
    option_4: [0, 0, 0, 0, 0],
    option_5: [0, 0, 0, 0, 0],
    option_6: [0, 0, 1, 1, 0],
    option_7: [0, 0, 0, 0, 0],
    option_8: [0, 0, 0, 0, 0],
    option_9: [0, 0, 0, 0, 0],
    option_10: [0, 0, 0, 0, 0],
    option_11: [0, 0, 0, 0, 0],
    option_12: [0, 0, 0, 0, 0],
    option_13: [0, 0, 0, 0, 0],
    option_14: [0, 0, 0, 0, 0],
    option_15: [0, 0, 0, 0, 0],
    option_16: [0, 0, 0, 0, 0],
    option_17: [0, 0, 0, 0, 0],
    option_18: [0, 0, 0, 0, 0],
    option_19: [0, 0, 0, 0, 0],
  },
  {
    researcher_id: "e35d2596-503d-4693-92cc-5f1ebc7ahw8f", // ผศ. ดร.ทรงพล ศรีวงค์ษา
    option_1: [0, 0, 0, 0, 0],
    option_2: [0, 0, 0, 0, 0],
    option_3: [0, 0, 0, 0, 0],
    option_4: [0, 0, 0, 0, 0],
    option_5: [0, 0, 0, 0, 0],
    option_6: [0, 0, 2, 3, 0],
    option_7: [0, 0, 0, 0, 0],
    option_8: [0, 0, 0, 0, 0],
    option_9: [0, 0, 0, 0, 0],
    option_10: [0, 0, 0, 0, 0],
    option_11: [0, 0, 0, 0, 0],
    option_12: [0, 0, 0, 0, 0],
    option_13: [0, 0, 0, 0, 0],
    option_14: [0, 0, 0, 0, 0],
    option_15: [0, 0, 0, 0, 0],
    option_16: [0, 0, 0, 0, 0],
    option_17: [0, 0, 0, 0, 0],
    option_18: [0, 0, 0, 0, 0],
    option_19: [0, 0, 0, 0, 0],
  },
  {
    researcher_id: "e35d2596-503d-4693-92cc-5f1ebc7et58f", // ผศ.ดร.ธีระพล สลีวงศ์
    option_1: [0, 0, 0, 0, 0],
    option_2: [0, 0, 0, 0, 0],
    option_3: [0, 0, 0, 0, 0],
    option_4: [0, 0, 0, 0, 0],
    option_5: [0, 0, 0, 0, 0],
    option_6: [0, 0, 2, 1, 0],
    option_7: [0, 0, 0, 0, 0],
    option_8: [0, 0, 0, 0, 0],
    option_9: [0, 0, 0, 0, 0],
    option_10: [0, 0, 0, 0, 0],
    option_11: [0, 0, 0, 0, 0],
    option_12: [0, 0, 0, 0, 0],
    option_13: [0, 0, 0, 0, 0],
    option_14: [0, 0, 0, 0, 0],
    option_15: [0, 0, 0, 0, 0],
    option_16: [0, 0, 0, 0, 0],
    option_17: [0, 0, 0, 0, 0],
    option_18: [0, 0, 0, 0, 0],
    option_19: [0, 0, 0, 0, 0],
  },
  {
    researcher_id: "e35d2596-503d-4693-92cc-5f1ebc7a151f", // ผศ.ดร.ปริญญา เสงี่ยมสุนทร
    option_1: [0, 0, 0, 0, 0],
    option_2: [0, 0, 0, 0, 0],
    option_3: [0, 0, 0, 0, 0],
    option_4: [0, 0, 0, 0, 0],
    option_5: [0, 0, 0, 0, 0],
    option_6: [0, 0, 0, 3, 0],
    option_7: [0, 0, 0, 0, 0],
    option_8: [0, 0, 0, 0, 0],
    option_9: [0, 0, 0, 0, 0],
    option_10: [0, 0, 0, 0, 0],
    option_11: [0, 0, 0, 0, 0],
    option_12: [0, 0, 0, 0, 0],
    option_13: [0, 0, 0, 0, 0],
    option_14: [0, 0, 0, 0, 0],
    option_15: [0, 0, 0, 0, 0],
    option_16: [0, 0, 0, 0, 0],
    option_17: [0, 0, 0, 0, 0],
    option_18: [0, 0, 0, 0, 0],
    option_19: [0, 0, 0, 0, 0],
  },
  {
    researcher_id: "e35d2596-503d-4693-92cc-5f1ebc7a158q", // ดร.ปรินทร์ ไชยปัญญา
    option_1: [0, 0, 0, 0, 0],
    option_2: [0, 0, 0, 1, 0],
    option_3: [0, 0, 0, 0, 0],
    option_4: [0, 0, 0, 0, 0],
    option_5: [0, 0, 0, 1, 0],
    option_6: [0, 0, 0, 1, 0],
    option_7: [0, 0, 0, 0, 0],
    option_8: [0, 0, 0, 0, 0],
    option_9: [0, 0, 0, 0, 0],
    option_10: [0, 0, 0, 0, 0],
    option_11: [0, 0, 0, 0, 0],
    option_12: [0, 0, 0, 0, 0],
    option_13: [0, 0, 0, 0, 0],
    option_14: [0, 0, 0, 0, 0],
    option_15: [0, 0, 0, 0, 0],
    option_16: [0, 0, 0, 0, 0],
    option_17: [0, 0, 0, 0, 0],
    option_18: [0, 0, 0, 0, 0],
    option_19: [0, 0, 0, 0, 0],
  },
  {
    researcher_id: "056a8764-dbb5-4583-859f-dac2933e0bec", // ดร.พรทิพย์ เดชพิชัย
    option_1: [0, 2, 2, 3, 0],
    option_2: [0, 0, 0, 0, 0],
    option_3: [0, 0, 0, 0, 0],
    option_4: [0, 0, 0, 0, 0],
    option_5: [0, 0, 0, 0, 0],
    option_6: [0, 0, 0, 1, 0],
    option_7: [0, 0, 0, 0, 0],
    option_8: [0, 0, 0, 0, 0],
    option_9: [0, 0, 0, 0, 0],
    option_10: [0, 0, 0, 0, 0],
    option_11: [0, 0, 0, 0, 0],
    option_12: [0, 0, 0, 0, 0],
    option_13: [0, 0, 0, 0, 0],
    option_14: [0, 0, 0, 0, 0],
    option_15: [0, 0, 0, 0, 0],
    option_16: [0, 0, 0, 0, 0],
    option_17: [0, 0, 0, 0, 0],
    option_18: [0, 0, 0, 0, 0],
    option_19: [0, 0, 0, 0, 0],
  },
  {
    researcher_id: "e35d2596-503d-4693-92cc-5f1ebc7a1585", // รศ. ดร.ภวธน เขมะวิชานุรัตน์
    option_1: [0, 0, 0, 0, 0],
    option_2: [0, 0, 0, 0, 0],
    option_3: [0, 0, 0, 0, 0],
    option_4: [0, 0, 0, 0, 0],
    option_5: [0, 0, 0, 0, 0],
    option_6: [0, 0, 0, 4, 0],
    option_7: [0, 0, 0, 0, 0],
    option_8: [0, 0, 0, 0, 0],
    option_9: [0, 0, 0, 0, 0],
    option_10: [0, 0, 0, 0, 0],
    option_11: [0, 0, 0, 0, 0],
    option_12: [0, 0, 0, 0, 0],
    option_13: [0, 0, 0, 0, 0],
    option_14: [0, 0, 0, 0, 0],
    option_15: [0, 0, 0, 0, 0],
    option_16: [0, 0, 0, 0, 0],
    option_17: [0, 0, 0, 0, 0],
    option_18: [0, 0, 0, 0, 0],
    option_19: [0, 0, 0, 0, 0],
  },
  {
    researcher_id: "e35d2596-503d-4693-92cc-5f1ebc7a15asd", // ศ.ดร.ภูมิ คำเอม
    option_1: [0, 0, 0, 0, 0],
    option_2: [0, 0, 0, 0, 0],
    option_3: [0, 0, 0, 0, 0],
    option_4: [0, 0, 0, 5, 0],
    option_5: [0, 0, 0, 0, 0],
    option_6: [0, 0, 0, 0, 0],
    option_7: [0, 0, 0, 0, 0],
    option_8: [0, 0, 0, 0, 0],
    option_9: [0, 0, 0, 0, 0],
    option_10: [0, 0, 0, 0, 0],
    option_11: [0, 0, 0, 0, 0],
    option_12: [0, 0, 0, 0, 0],
    option_13: [0, 0, 0, 0, 0],
    option_14: [0, 0, 0, 0, 0],
    option_15: [0, 0, 0, 0, 0],
    option_16: [0, 0, 0, 0, 0],
    option_17: [0, 0, 0, 0, 0],
    option_18: [0, 0, 0, 0, 0],
    option_19: [0, 0, 0, 0, 0],
  },
  {
    researcher_id: "e35d2596-503d-4693-92cc-5f1ebc7a158w", // ผศ.ดร.วราภรณ์ จาตนิล
    option_1: [1, 0, 0, 4, 0],
    option_2: [0, 0, 0, 0, 0],
    option_3: [0, 0, 0, 0, 0],
    option_4: [0, 0, 0, 0, 0],
    option_5: [1, 0, 0, 0, 0],
    option_6: [0, 0, 0, 0, 0],
    option_7: [0, 0, 0, 0, 0],
    option_8: [0, 0, 0, 0, 0],
    option_9: [0, 0, 0, 0, 0],
    option_10: [0, 0, 0, 0, 0],
    option_11: [0, 0, 0, 0, 0],
    option_12: [0, 0, 0, 0, 0],
    option_13: [0, 0, 0, 0, 0],
    option_14: [0, 0, 0, 0, 0],
    option_15: [0, 0, 0, 0, 0],
    option_16: [0, 0, 0, 0, 0],
    option_17: [0, 0, 0, 0, 0],
    option_18: [0, 0, 0, 0, 0],
    option_19: [0, 0, 0, 0, 0],
  },
  {
    researcher_id: "71c2a2db-58cf-4616-892b-b486948953bd", // ดร.วรินทร์ วัฒนพรพรหม
    option_1: [0, 0, 0, 0, 0],
    option_2: [0, 0, 0, 0, 1],
    option_3: [0, 0, 0, 0, 0],
    option_4: [0, 0, 0, 0, 0],
    option_5: [0, 0, 0, 0, 0],
    option_6: [0, 0, 0, 2, 1],
    option_7: [0, 0, 0, 0, 0],
    option_8: [0, 0, 0, 0, 0],
    option_9: [0, 0, 0, 0, 0],
    option_10: [0, 0, 0, 0, 0],
    option_11: [0, 0, 0, 0, 0],
    option_12: [0, 0, 0, 0, 0],
    option_13: [0, 0, 0, 0, 0],
    option_14: [0, 0, 0, 0, 0],
    option_15: [0, 0, 0, 0, 0],
    option_16: [0, 0, 0, 0, 0],
    option_17: [0, 0, 0, 0, 0],
    option_18: [0, 0, 0, 0, 0],
    option_19: [0, 0, 0, 0, 0],
  },
  {
    researcher_id: "e35d2596-503d-4693-92cc-5f1ebc7a158a", // ผศ. ดร.วริสา ยมเสถียรกุล
    option_1: [0, 0, 0, 0, 0],
    option_2: [0, 0, 0, 0, 0],
    option_3: [0, 0, 0, 0, 0],
    option_4: [0, 0, 0, 0, 0],
    option_5: [0, 0, 0, 0, 0],
    option_6: [1, 1, 1, 0, 0],
    option_7: [0, 0, 0, 0, 0],
    option_8: [0, 0, 0, 0, 0],
    option_9: [0, 0, 0, 0, 0],
    option_10: [0, 0, 0, 0, 0],
    option_11: [0, 0, 0, 0, 0],
    option_12: [0, 0, 0, 0, 0],
    option_13: [0, 0, 0, 0, 0],
    option_14: [0, 0, 0, 0, 0],
    option_15: [0, 0, 0, 0, 0],
    option_16: [0, 0, 0, 0, 0],
    option_17: [0, 0, 0, 0, 0],
    option_18: [0, 0, 0, 0, 0],
    option_19: [0, 0, 0, 0, 0],
  },
  {
    researcher_id: "db791bb3-36e4-4121-a10a-c2327b34f5f9", // รศ. ดร.วิบูลศักดิ์ วัฒายุ
    option_1: [0, 0, 0, 0, 0],
    option_2: [0, 0, 0, 3, 0],
    option_3: [0, 0, 0, 0, 0],
    option_4: [0, 0, 0, 0, 0],
    option_5: [0, 0, 0, 0, 0],
    option_6: [0, 0, 0, 0, 0],
    option_7: [0, 0, 0, 0, 0],
    option_8: [0, 0, 0, 0, 0],
    option_9: [0, 0, 0, 0, 0],
    option_10: [0, 0, 0, 0, 0],
    option_11: [0, 0, 0, 0, 0],
    option_12: [0, 0, 0, 0, 0],
    option_13: [0, 0, 0, 0, 0],
    option_14: [0, 0, 0, 0, 0],
    option_15: [0, 0, 0, 0, 0],
    option_16: [0, 0, 0, 0, 0],
    option_17: [0, 0, 0, 0, 0],
    option_18: [0, 0, 0, 0, 0],
    option_19: [0, 0, 0, 0, 0],
  },
  {
    researcher_id: "db791bb3-36e4-4121-a10a-c2327b34f5f9", // รศ.ดร.เศรษฐภัทร ชินวิริยสิทธิ์
    option_1: [0, 0, 0, 0, 0],
    option_2: [0, 0, 0, 0, 0],
    option_3: [0, 0, 0, 0, 0],
    option_4: [0, 0, 0, 0, 0],
    option_5: [1, 0, 0, 0, 0],
    option_6: [0, 1, 1, 0, 0],
    option_7: [0, 0, 0, 0, 0],
    option_8: [0, 0, 0, 0, 0],
    option_9: [0, 0, 0, 0, 0],
    option_10: [0, 0, 0, 0, 0],
    option_11: [0, 0, 0, 0, 0],
    option_12: [0, 0, 0, 0, 0],
    option_13: [0, 0, 0, 0, 0],
    option_14: [0, 0, 0, 0, 0],
    option_15: [0, 0, 0, 0, 0],
    option_16: [0, 0, 0, 0, 0],
    option_17: [0, 0, 0, 0, 0],
    option_18: [0, 0, 0, 0, 0],
    option_19: [0, 0, 0, 0, 0],
  },
  {
    researcher_id: "db791bb3-36e4-4121-a10a-c2327b34f5f9", // ดร.อนุวัฒน์ ตั้งธนวัฒน์สกุล
    option_1: [0, 0, 0, 0, 0],
    option_2: [0, 0, 0, 0, 0],
    option_3: [0, 0, 0, 0, 0],
    option_4: [0, 0, 0, 0, 0],
    option_5: [0, 0, 0, 0, 0],
    option_6: [0, 0, 1, 2, 0],
    option_7: [0, 0, 0, 0, 0],
    option_8: [0, 0, 0, 0, 0],
    option_9: [0, 0, 0, 0, 0],
    option_10: [0, 0, 0, 0, 0],
    option_11: [0, 0, 0, 0, 0],
    option_12: [0, 0, 0, 0, 0],
    option_13: [0, 0, 0, 0, 0],
    option_14: [0, 0, 0, 0, 0],
    option_15: [0, 0, 0, 0, 0],
    option_16: [0, 0, 0, 0, 0],
    option_17: [0, 0, 0, 0, 0],
    option_18: [0, 0, 0, 0, 0],
    option_19: [0, 0, 0, 0, 0],
  },
];
