import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import CarouselItem from "./CarouselItem";
import kmuttMath from "../../assets/images/kmutt-math-slider.jpeg";
import kmuttMath2 from "../../assets/images/kmutt-math-slider-2.jpeg";

const CarouselSlider: React.FC = () => {
  const imagesSlider = [kmuttMath, kmuttMath2];

  return (
    <Swiper
      modules={[Pagination, Autoplay, EffectFade]}
      navigation={{
        nextEl: ".swiper-button-next-custom",
        prevEl: ".swiper-button-prev-custom",
      }}
      pagination={{ clickable: true }}
      slidesPerView={1}
      loop={true}
      effect={"fade"}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
    >
      {imagesSlider.map((item) => {
        return (
          <SwiperSlide key={Math.random()}>
            <CarouselItem image={item} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default CarouselSlider;
