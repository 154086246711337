import React from "react";

interface ButtonProps {
  text: string;
  onClick: (e: any) => void;
}

const Button: React.FC<ButtonProps> = (props) => {
  return (
    <div>
      <button
        onClick={props.onClick}
        className="text-lg py-2 px-12 rounded-xl flex bg-orange_theme hover:bg-opacity-80 duration-150 ease-in-out"
      >
        <div className="m-auto text-white">{props.text}</div>
      </button>
    </div>
  );
};

export default Button;
