import React from "react";
import KmuttLOGO from "../assets/images/kmutt-logo.png";
import { useNavigate } from "react-router-dom";

interface SignInProps {
  children: React.ReactNode;
  error?: string;
  submit: (e: React.SyntheticEvent) => void;
  title: string;
}

const SignIn: React.FC<SignInProps> = (props) => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white flex items-center justify-center">
      <form
        className="w-[40vw] h-fit border-2 border-blue_gray_theme shadow-lg rounded-lg p-10 max-md:p-2 max-md:w-[70vw]"
        onSubmit={props.submit}
      >
        <div className="flex items-center justify-center flex-col">
          <div className="w-20 h-20">
            <img src={KmuttLOGO} alt="kmutt-logo" className="bg-cover" />
          </div>
          <p className="text-3xl font-extralight py-3 text-[3vw] max-md:text-[7vw] xl:text-[2vw]">
            {props.title}
          </p>
          <p className="text-xl font-extralight text-[1.5vw] max-md:text-[4.5vw]">
            {props.title === "Sign in" ? "with your account" : "your account"}
          </p>
          <div className="py-5">{props.children}</div>
        </div>
        <div className="flex justify-between items-center mt-[vw]">
          <div className="hover:bg-[#edf4ff] duration-150 ease-in-out rounded-lg bg-opacity-90">
            <p
              className="cursor-pointer text-[1.5vw] max-md:text-[3.5vw] xl:text-[1vw] text-[#4c8bf5] block px-5 py-2"
              onClick={() =>
                navigate(
                  `${props.title === "Sign in" ? "/register" : "/login"}`
                )
              }
            >
              {props.title === "Sign in"
                ? "Create an account"
                : "Back to login"}
            </p>
          </div>
          <div>
            <button
              className="px-5 py-2 bg-[#4c8bf5] text-white rounded-md hover:bg-blue-400 duration-100 ease-in-out"
              onClick={props.submit}
              type="submit"
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignIn;
