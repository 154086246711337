import { makeAutoObservable, configure, action } from "mobx";
import { RESEARCHER, ResearcherType } from "../mocks/researcher";
import { makeSelectOptions } from "../helpers/makeSelectOptions";
import { Statistic } from "../mocks/statistic";
import { FetchCountResearchOptionALL } from "../api/researchChart/fetchCountResearchOptionALL";
import { FetchCountResearchOptionByResearcherID } from "../api/researchChart/fetchCountResearchOptionByResearcherID";
import type { Research_Option } from "../interfaces/ResearchOption";
import type { FetchCountResearchOptionALLResponse } from "../api/researchChart/fetchCountResearchOptionALL";
import {
  Statistic_Researcher,
  Statistic_ResearcherType,
} from "../mocks/statistic_per_researcher";

configure({
  enforceActions: "never",
});

export class ChartStoreImpl {
  researcher: ResearcherType[] = RESEARCHER;
  researcher_selected: string = "ALL";
  // researcher_research_statistic: Research_Option = {} as Research_Option; // <- uncomment this line to use fetch data
  researcher_research_statistic: Statistic_ResearcherType = Statistic; // // <- comment this line to use fetch data

  constructor() {
    makeAutoObservable(this);
  }

  @action
  get getResearcher() {
    return makeSelectOptions(RESEARCHER);
  }

  @action
  get getResearcherInfo() {
    const researcher_data = RESEARCHER.filter(
      (item) => item.id === this.researcher_selected
    )[0];
    return researcher_data;
  }

  @action
  getStatisticByOption(option: string): Research_Option {
    if (option === "ALL") {
      return Statistic;
    } // <- comment this line to use fetch data

    const mock_data = Statistic_Researcher.filter((item) => {
      return item.researcher_id === option;
    })[0]; // <- comment this line to use fetch data

    delete mock_data.researcher_id; // <- comment this line to use fetch data

    if (!mock_data) {
      return {} as Research_Option;
    } // <- comment this line to use fetch data

    return mock_data as Research_Option; // <- comment this line to use fetch data

    // return this.researcher_research_statistic[
    //   option as keyof typeof this.researcher_research_statistic
    // ]; // <- uncomment this line to use fetch data
  }

  @action
  async fetchStatistic(option: string) {
    var response: FetchCountResearchOptionALLResponse;

    if (option === "ALL") {
      response = await FetchCountResearchOptionALL();
    } else {
      response = await FetchCountResearchOptionByResearcherID(option);
    }

    return response.statistic; // <- await fetch data
  }

  @action
  getAllStatistic(value: Research_Option) {
    if (value !== undefined) {
      const all_statistic = [0, 0, 0, 0, 0];
      for (let i = 1; i <= 19; i++) {
        const option = `option_${i}`;
        for (let j = 0; j < 5; j++) {
          if (value[option as keyof Research_Option] !== undefined) {
            all_statistic[j] += value[option as keyof Research_Option][j];
          }
        }
      }
      return all_statistic;
    } else {
      return [0, 0, 0, 0, 0];
    }
  }
}

export const ChartStore = new ChartStoreImpl();
