import React from "react";

interface BackdropProps {
  handleClose: () => void;
  children: React.ReactNode;
}

const Backdrop: React.FC<BackdropProps> = (props) => {
  const handleClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      props.handleClose();
    }
  };

  return (
    <div
      className="fixed top-0 left-0 w-[100vw] h-[100vh] bg-black bg-opacity-40 flex items-center justify-center flex-col"
      onClick={handleClick}
    >
      {props.children}
    </div>
  );
};

export default Backdrop;
