import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="clear-both w-[100vw] bg-blue_gray_theme flex justify-end items-center h-[5vh] mt-auto border-2">
      <div>
        <p className="text-white text-[1.5vw] mt-2 mr-5">© 2021 MRD</p>
      </div>
    </footer>
  );
};

export default Footer;
