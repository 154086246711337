import axios from "axios";
import { BaseURL } from "../baseURl";

interface LoginRouterParams {
  email: string;
  password: string;
}

interface ResponseLoginRouter {
  status: number;
  message: string;
  token?: string;
}

export const LoginRouter = async ({
  email,
  password,
}: LoginRouterParams): Promise<ResponseLoginRouter> => {
  const response: ResponseLoginRouter = await axios
    .post<ResponseLoginRouter>(`${BaseURL}/user/login`, {
      email,
      password,
    })
    .then((res) => {
      const status = res.status;
      const message = res.data.message;

      localStorage.setItem("access_token", res.data.token as string);

      return { status, message };
    })
    .catch((err) => {
      const status = err.response.status;
      const message = err.response.data.message;

      return { status, message };
    });

  return response;
};
