import React, { useEffect, useState } from "react";
import { ResearcherType } from "../mocks/researcher";
import { ChartStoreImpl } from "../store/ChartStore";
import { observer } from "mobx-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Backdrop from "./Backdrop";
import ResearcherPersonalData from "./ResearcherPersonalData";

interface ReacherInfoProps {
  chartStore: ChartStoreImpl;
}

const ResearcherInfo: React.FC<ReacherInfoProps> = observer((props) => {
  const [researcher_info, setResearcher_info] = useState<ResearcherType>();
  const [showInfo, setShowInfo] = useState<boolean>(false);

  useEffect(() => {
    setResearcher_info(props.chartStore.getResearcherInfo);
  }, [props.chartStore, props.chartStore.researcher_selected]);

  const handleClick = () => {
    if (window.innerWidth >= 768) {
      setShowInfo(true);
    }
  };

  const handleClose = () => {
    setShowInfo(false);
  };

  return (
    <div className="h-[70vh] w-[20vw] bg-white px-5 py-2 rounded-md shadow-lg max-md:w-[60vw] animate-popup flex flex-col justify-center items-center max-lg:h-fit">
      <div
        onClick={handleClick}
        className="overflow-scroll flex items-center justify-center flex-col"
      >
        <div className="flex justify-center max-md:w-[10rem] max-md:h-[15rem]">
          <LazyLoadImage
            src={`https://math.kmutt.ac.th${researcher_info?.image}`}
            loading="lazy"
            className="cursor-pointer"
          />
        </div>
        <div className="my-5 overflow-scroll hover:underline hover:decoration-orange_theme cursor-pointer max-md:h-fit">
          <div className="flex justify-center flex-col">
            <strong className="text-lg text-center">
              {researcher_info?.name}
            </strong>
            <p className="text-sm text-center">
              {researcher_info?.english_name}
            </p>
          </div>
        </div>
      </div>
      {showInfo ? (
        <Backdrop handleClose={handleClose}>
          <ResearcherPersonalData
            image={researcher_info?.image}
            education={researcher_info?.education}
            research_area={researcher_info?.research_area}
            name={researcher_info?.name}
            english_name={researcher_info?.english_name}
            phone={researcher_info?.tel}
            email={researcher_info?.email}
          />
        </Backdrop>
      ) : (
        ""
      )}
    </div>
  );
});

export default ResearcherInfo;
