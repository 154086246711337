interface selectOptionType {
  value: string;
  label: string;
}

export const makeSelectOptions = (options: any[]): selectOptionType[] => {
  return options.map((option) => {
    return {
      value: option.id,
      label: option.name,
    };
  });
};

export const createRandomOptions = (n: number): selectOptionType[] => {
  const options: selectOptionType[] = [];
  for (let i = 0; i < n; i++) {
    options.push({
      value: i.toString(),
      label: `Option ${i}`,
    });
  }
  return options;
};

export const createOptionfiveYearsBack = (): selectOptionType[] => {
  const options: selectOptionType[] = [];
  for (let i = 0; i < 5; i++) {
    options.push({
      value: i.toString(),
      label: `${new Date().getFullYear() - i}`,
    });
  }
  return options;
};
