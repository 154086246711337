import React from "react";

interface TextFieldProps {
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  name?: string;
  pattern?: string;
  minLength?: number;
  maxLength?: number;
  error?: string;
}

const TextField: React.FC<TextFieldProps> = (props) => {
  return (
    <div className="flex relative">
      <input
        className={`w-[25vw] h-[2rem] border-2 rounded-md p-5 outline-none focus:border-yellow_theme duration-150 ease-in-out max-md:w-[40vw] ${
          props.error ? "border-red-400 focus:border-red-400" : ""
        } `}
        placeholder={props.placeholder}
        name={props.name}
        onChange={props.onChange}
        value={props.value}
        type={props.type}
        required
        pattern={props.pattern}
      />
      {props.error ? (
        <div className="absolute text-red-500 bottom-[-12px] left-2 text-[8px]">
          {props.error}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TextField;
