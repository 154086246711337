import React, { useEffect, useState } from "react";
import Detail from "../components/Detail";
import Navbar from "../components/Navbar";
import { ResearchStoreImpl } from "../store/ResearchStore";
import { useNavigate, useParams } from "react-router-dom";
import AccessTokenProvider from "../provider/AccessTokenProvider";
import { WEBSITE_PATH } from "../config/Path";
import { FetchResearchById } from "../api/researchDetail/fetchResearchById";
import type { ResearchType } from "../interfaces/ResearchType";

interface ResearchDescriptionProps {
  researchStore: ResearchStoreImpl;
}

const ResearchDetail: React.FC<ResearchDescriptionProps> = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [researchData, setResearchData] = useState<ResearchType>(
    {} as ResearchType
  );

  useEffect(() => {
    // fetch research_id by param id

    async function fetch() {
      if (id) {
        const response = await FetchResearchById(id);
        if (response.status !== 200) {
          navigate(WEBSITE_PATH.RESEARCH);
          return;
        }
        setResearchData(response.research);
      } else {
        navigate(WEBSITE_PATH.RESEARCH);
      }
    }

    // fetch(); // <- uncomment this line if you want to fetch research by id
  }, [props.researchStore, navigate, id]);

  return (
    <AccessTokenProvider>
      <div className="flex justify-center items-center relative overflow-hidden min-h-screen">
        <Navbar />
        <div className="mt-[25vh] z-[10] h-fit ">
          <Detail researchStore={props.researchStore} />
        </div>
        <div className=" w-[20rem] h-[100vh] rotate-45 absolute bottom-[-50%] right-[-20%] bg-orange_theme max-lg:hidden"></div>
        <div className=" w-[20rem] h-[100vh] rotate-45 absolute top-[10%] left-[-40%] bg-orange_theme max-lg:hidden xl:left-[-28%] rounded-lg"></div>
      </div>
    </AccessTokenProvider>
  );
};

export default ResearchDetail;
