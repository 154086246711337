import axios from "axios";
import { ResearchType } from "../../interfaces/ResearchType";
import { BaseURL } from "../baseURl";

export interface FetchResearchBySelectResponse {
  message: string;
  research: ResearchType[];
  status: number;
}

export interface FetchResearchParameter {
  researcher_id: string;
  quartile: number;
  research_type: string;
}

export const FetchResearchBySelect = async ({
  research_type,
  researcher_id,
  quartile,
}: FetchResearchParameter): Promise<FetchResearchBySelectResponse> => {
  const response: FetchResearchBySelectResponse = await axios
    .post<FetchResearchBySelectResponse>(`${BaseURL}/research/select`, {
      research_type,
      researcher_id,
      quartile,
    })
    .then((res) => {
      const status = res.status;

      return {
        status: status,
        research: res.data.research,
        message: res.data.message,
      };
    })
    .catch((err) => {
      const status = err.response.status;
      return {
        status,
        message: err.response.data.message,
        research: [] as ResearchType[],
      };
    });

  return response;
};
