import React from "react";
import { useParams } from "react-router-dom";

const WaitVerifyEmail: React.FC = () => {
  const { id } = useParams();

  return (
    <div className="min-h-screen p-5 flex items-center justify-center">
      <div className="flex flex-col items-center">
        <p className="text-2xl">
          Hi &nbsp;
          <strong className="text-orange_theme animate-textAnimation">
            {id}
          </strong>
        </p>
        <p className="text-xl">
          Please check your email to verify your account. Thank you!
        </p>
        <div>
          <button
            onClick={() => {
              window.location.href = "https://mail.google.com/mail";
            }}
            className="bg-orange_theme text-white px-5 py-2 rounded-md mt-5 hover:bg-orange_theme hover:bg-opacity-40 ease-in-out duration-150 hover:text-orange_theme"
          >
            Move to Gmail
          </button>
        </div>
      </div>
    </div>
  );
};

export default WaitVerifyEmail;
