import React from "react";
import Select, { SingleValue } from "react-select";

interface optionType {
  value: string;
  label: string;
}

interface SelectedProps {
  handleChange: (
    selected: SingleValue<{ value: string; label: string }>
  ) => void;
  placeholder: string;
  data: optionType[];
  custom?: string;
  menuPortalZIndex?: number;
  menu?: number;
  selectCustom?: string;
}

const Selected: React.FC<SelectedProps> = (props) => {
  const options = [{ value: "ALL", label: "ทั้งหมด" }, ...props.data];

  return (
    <div
      className={`${
        props.custom ? props.custom : "w-[50vw] mt-20 mb-5"
      } z-[10]`}
    >
      <Select
        className={`max-md:text-[2.5vw] ${
          props.selectCustom ? props.selectCustom : ""
        }`}
        options={options}
        placeholder={props.placeholder}
        onChange={props.handleChange}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (provided) => ({
            ...provided,
            zIndex: props.menuPortalZIndex ? props.menuPortalZIndex : 9999,
          }),
          menu: (provided) => ({
            ...provided,
            zIndex: props.menu ? props.menu : 9999,
          }),
        }}
      />
    </div>
  );
};

export default Selected;
