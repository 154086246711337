import React, {useState} from 'react';
import Layout from "../../../components/layouts/layout";
import { Link } from "react-router-dom";
import data from "../../../research.json";

export const Menu = () => {
	
	return (
		<Layout>
            <p className="mb-3 text-xl font-bold">แก้ไขข้อมูลรายงาน</p>
			<div className='bg-white p-3 drop-shadow rounded-md'>
				<div className='flex pb-3'>
					<div className='ml-auto'>
						<input type="text" id="table-search" 
						className="block p-2 md:w-60 text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items" />
					</div>
				</div>
				<div className='overflow-x-auto relative'>
					<table className="border border-slate-300 w-full">
						<thead className='border-b border-slate-300 bg-slate-100 py-3'>
							<tr>
								<th scope="col" className='p-3 px-6 font-bold'>#</th>
								<th scope="col" className='p-3 px-6 font-bold'>ชื่อเรื่อง</th>
								<th scope="col" className='p-3 px-6 font-bold'>วันที่เผยแพร่</th>
								<th scope="col" className='p-3 px-6 font-bold'></th>
							</tr>
						</thead>
						<tbody>
							{data.map( data => (
								<tr className='border-b border-slate-300' key={data.id}>
									<td className='text-center p-3 px-6'>{data.id}</td>
									<td className='text-md p-3 px-6'>{data.title}</td>
									<td className='text-center p-3 px-6'>{data.public_date}</td>
									<td className='text-center p-3 px-6'>
										<Link to={{pathname: `/edit/research/${data.id}`,}}>
											<button className='px-4 py-1 rounded-md bg-amber-300 transition duration-200 hover:bg-amber-400'>จัดการ</button>
										</Link>
									</td>
								</tr>
							))}
							
						</tbody>
					</table>
				</div>
            </div>
		</Layout>
	);
};

export default Menu;