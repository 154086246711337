import React from "react";
import nYearBack from "../../helpers/nYearBack";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartLayout from "./ChartLayout";
import { observer } from "mobx-react";
import { ChartStoreImpl } from "../../store/ChartStore";
import { Research_type_option } from "../../mocks/research_type_option";
import type { Research_Option } from "../../interfaces/ResearchOption";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ResearchChartProps {
  chartStore: ChartStoreImpl;
  chartType: string;
  researcher: string;
  researchOption: Research_Option;
}

const ResearchChart: React.FC<ResearchChartProps> = observer((props) => {
  const data = {
    labels: nYearBack(5).map((year) => `ปี ${year}`),
    datasets: [
      {
        label:
          props.chartType === "ALL"
            ? "ผลงานวิจัยทั้งหมด"
            : Research_type_option.filter(
                (option) => option.value === props.chartType
              )[0].label.slice(0, 40) + "...",
        data:
          props.chartType === "ALL"
            ? props.chartStore.getAllStatistic(props.researchOption)
            : props.researchOption[props.chartType as keyof Research_Option],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgb(75, 192, 192)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: [
          props.researcher === "ทั้งหมด"
            ? "ผลงานวิจัยทั้งหมดย้อนหลัง 5 ปี"
            : `ผลงานวิจัยของ ${props.researcher} ย้อนหลัง 5 ปี`,
        ],
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 5,
        },
        suggestedMax: 5,
        stacked: true,
      },
      x: {
        stacked: true,
      },
    },
  };

  return (
    <ChartLayout>
      <Bar data={data} options={options} />
    </ChartLayout>
  );
});

export default ResearchChart;
