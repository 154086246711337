import React from "react";
import "../../styles/Table.css";
import { observer } from "mobx-react";
import { ResearchStoreImpl } from "../../store/ResearchStore";
import { ResearchCollectionType } from "../../mocks/researchCollection";
import { useNavigate } from "react-router-dom";
import { WEBSITE_PATH } from "../../config/Path";

interface ResearchTableProps {
  researchStore: ResearchStoreImpl;
}

const ResearchTable: React.FC<ResearchTableProps> = observer((props) => {
  const navigate = useNavigate();

  const handleClick = (item: string) => {
    navigate(WEBSITE_PATH.RESEARCH_DETAIL.slice(0, -3) + item);
  };

  return (
    <div className="bg-white h-[55vh] px-5 overflow-scroll">
      <table>
        <thead>
          <tr>
            <th className="w-[40rem] text-center max-md:text-[2.5vw]">
              Research title
            </th>
            <th className="w-[10rem] text-center max-md:text-[2.5vw]">
              Researcher
            </th>
            <th className="w-[10rem] text-center max-md:text-[2.5vw]">
              Publish date
            </th>
          </tr>
        </thead>
        <tbody>
          {props.researchStore.research_collection_pagination.map((item) => {
            return (
              <tr
                className="cursor-pointer hover:bg-slate-100  duration-75 ease-in-out max-md:text-[1.5vw]"
                key={item.research_id}
                onClick={() => handleClick(item.research_id)}
              >
                <td className="max-md:text-[1.7vw]">{item.title}</td>
                <td className="text-[.6rem] max-md:text-[1.7vw]">
                  {item.researcher_id.english_name}
                </td>
                <td className="text-center max-md:text-[1.7vw]">
                  {item.publish_year}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
});

export default ResearchTable;
