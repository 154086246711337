import axios from "axios";
import type { Research_Option } from "../../interfaces/ResearchOption";
import { BaseURL } from "../baseURl";

interface FetchCountResearchOptionByResearcherIDResponse {
  message: string;
  statistic: Research_Option;
  status: number;
}

export const FetchCountResearchOptionByResearcherID = async (
  researcherID: string
): Promise<FetchCountResearchOptionByResearcherIDResponse> => {
  const response: FetchCountResearchOptionByResearcherIDResponse = await axios
    .get<FetchCountResearchOptionByResearcherIDResponse>(
      `${BaseURL}/research/researcher/${researcherID}`
    )
    .then((res) => {
      const status = res.status;

      return {
        status: status,
        statistic: res.data.statistic,
        message: res.data.message,
      };
    })
    .catch((err) => {
      const status = err.response.status;
      return {
        status,
        message: err.response.data.message,
        statistic: {} as Research_Option,
      };
    });

  return {
    message: response.message,
    statistic: response.statistic,
    status: response.status,
  };
};
