import { FetchResearchBySelectResponse } from "../api/researchTable/fetchResearchBySelect";
import { v4 as uuidv4 } from "uuid";

export const Research: FetchResearchBySelectResponse = {
  message: "unexpected error",
  research: [
    {
      research_id: "b0e2c522-9909-4f48-a564-5136f22a2d02",
      title:
        "An improved numerical method for variational image registration model with intensity correction",
      publish_year: 2021,
      journal: "Journal of Interdisciplinary Mathematics (2021)",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-50tb-4693-92cc-5f1ebc7a158f",
        name: "ดร.ชัชวาลย์ วัชราเรืองวิทย์",
        english_name: "Dr.Chatchawan Watchararuangwit",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "8dd42878-ba85-4adc-b681-361f6bbb1b13",
      title:
        "A Variational image registration model for MRI images with intensity inhomogeneities",
      publish_year: 2019,
      journal: "The 24th Annual Meeting in Mathematics (AMM 2019)",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-50tb-4693-92cc-5f1ebc7a158f",
        name: "ดร.ชัชวาลย์ วัชราเรืองวิทย์",
        english_name: "Dr.Chatchawan Watchararuangwit",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "e7872041-3331-41b8-b45d-3b966253e7f8",
      title:
        "A Study and Improvement of Image Registration Techniques for Mammographic Images",
      publish_year: 2018,
      journal: "การประชุมวิชาการทางคณิตศาสตร์ครั้งที่ 23 ประจำปี พ.ศ. 2561",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-50tb-4693-92cc-5f1ebc7a158f",
        name: "ดร.ชัชวาลย์ วัชราเรืองวิทย์",
        english_name: "Dr.Chatchawan Watchararuangwit",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "36d7c4a5-066d-42b3-a3b4-331999539c43",
      title: "Ensemble Classifier for Stock Trading Recommendation",
      publish_year: 2021,
      journal: "Applied Artificial Intelligence (2021)",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "8c1865b6-51ae-4dec-8f64-5c923dd521b3",
        name: "รศ.ชูเกียรติ วรสุชีพ",
        english_name: "Assoc. Prof. Chukiat Worasucheep",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "670828a1-46be-4649-a8ca-dd3703f9ad48",
      title:
        "Quantitative Trading Machine Learning Using Differential Evolution Algorithm",
      publish_year: 2019,
      journal:
        "16th International Joint Conference on Computer Science and Software Engineering (JCSSE) 2019",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "8c1865b6-51ae-4dec-8f64-5c923dd521b3",
        name: "รศ.ชูเกียรติ วรสุชีพ",
        english_name: "Assoc. Prof. Chukiat Worasucheep",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "465f60f8-b740-488e-942a-56c4593bf4b3",
      title:
        "“Enhancement of Gravitational Search Algorithm using A Differential Mutation Operator and Its Application on Reconstructing Gene Regulatory Network”",
      publish_year: 2019,
      journal:
        "ECTI Transactions on Computer and Information Technology (ECTI-CIT) (2019)",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "8c1865b6-51ae-4dec-8f64-5c923dd521b3",
        name: "รศ.ชูเกียรติ วรสุชีพ",
        english_name: "Assoc. Prof. Chukiat Worasucheep",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "7b8dc024-fe0e-4c56-97b2-9900f8c4bf72",
      title: "IoT System Design for Agro-Tourism, 2021",
      publish_year: 2021,
      journal:
        "2021 18th International Joint Conference on Computer Science and Software Engineering (JCSSE) Materials Science and Engineering: C (2021)",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "415d2596-503d-4693-92cc-5f1ebc7a158f",
        name: "ดร.ฐิตาภรณ์​ กนกรัตน",
        english_name: "Dr.Thittaporn Ganokratanaa",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "a1d44d0e-15fc-4cb9-9c39-1a1b6cdc12d5",
      title: "Deep Index Price Forecasting in Steel Industry, 2021",
      publish_year: 2021,
      journal:
        "2021 18th International Joint Conference on Computer Science and Software Engineering (JCSSE) Materials Science and Engineering: C (2021)",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "415d2596-503d-4693-92cc-5f1ebc7a158f",
        name: "ดร.ฐิตาภรณ์​ กนกรัตน",
        english_name: "Dr.Thittaporn Ganokratanaa",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "7b8dc024-fe0e-4c56-97b2-9900f8c4bf72",
      title:
        "Unsupervised Video Anomaly Detection Based on Spatiotemporal Generative Adversarial Network,2021",
      publish_year: 2021,
      journal:
        "2021 18th International Joint Conference on Computer Science and Software Engineering (JCSSE) Materials Science and Engineering: C (2021)",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "415d2596-503d-4693-92cc-5f1ebc7a158f",
        name: "ดร.ฐิตาภรณ์​ กนกรัตน",
        english_name: "Dr.Thittaporn Ganokratanaa",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "8abfa5da-6afc-4012-bdd8-8b7d79419498",
      title:
        "On some new paraproduct uniform estimate relating to T1 theorem, 2019",
      publish_year: 2019,
      journal: "PanAmerican Mathematical Journal",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-2493-92cc-5f1ebc7a158f",
        name: "ดร.ณฐวัฒน์ กล่ำสกุล",
        english_name: "Dr.Natawat Klamsakul",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "cce37e6f-3c0f-40f3-b5be-e09ff25b8182",
      title: "Problem Solving with a Project Approach, 2021",
      publish_year: 2021,
      journal:
        "Proceedings of the 44th conference of the international group for the psychology of Mathematics education",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-2493-92cc-5f1ebc7a158f",
        name: "ดร.ณฐวัฒน์ กล่ำสกุล",
        english_name: "Dr.Natawat Klamsakul",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "70fcfbb4-0f09-41bd-9cac-eaec868f72f0",
      title: "Tutorial Teaching for Large Groups of Students, 2020",
      publish_year: 2020,
      journal: "the 2020 International STEM Education Conference",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-2493-92cc-5f1ebc7a158f",
        name: "ดร.ณฐวัฒน์ กล่ำสกุล",
        english_name: "Dr.Natawat Klamsakul",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "ed80fa00-5a79-4814-ba8a-cfc612656bb2",
      title:
        "Stein's method for negatively associated random variables with applications to second order stationary random fields, 2018",
      publish_year: 2018,
      journal: "Journal of Applied Probability",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e2a393dd-232c-49e5-9e50-eda45714b8d4",
        name: "ผศ. ดร.ณรรฐคุณ วิรุฬห์ศรี",
        english_name: "Asst. Prof. Dr.Nathakhun Wiroonsri",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "79df696d-ca8c-4359-b106-dec622ae61f3",
      title:
        "Normal approximation for associated point processes via Stein's method with applications to determinantal point processes,2019",
      publish_year: 2019,
      journal: "Journal of Mathematical Analysis and Applications ",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e2a393dd-232c-49e5-9e50-eda45714b8d4",
        name: "ผศ. ดร.ณรรฐคุณ วิรุฬห์ศรี",
        english_name: "Asst. Prof. Dr.Nathakhun Wiroonsri",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "42d061d6-1eb5-4032-b46f-f5b2fd7e88ec",
      title:
        "Stein's method for positively associated random variables with applications to the Ising and voter models, bond percolation, and contact process, 2018",
      publish_year: 2018,
      journal: "Annales de l'Institut Henri Poincaré",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e2a393dd-232c-49e5-9e50-eda45714b8d4",
        name: "ผศ. ดร.ณรรฐคุณ วิรุฬห์ศรี",
        english_name: "Asst. Prof. Dr.Nathakhun Wiroonsri",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "361d9dc6-7ce4-4959-9702-53050c5a8727",
      title:
        "Option Pricing Model with Transaction Costs and Jumps in illiquid Market, 2021",
      publish_year: 2021,
      journal: "Journal of Mathematical Finance",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "835fc675-b551-4aa9-9d47-fe355f0d1653",
        name: "ผศ.ดร.ดาวุด ทองทา",
        english_name: "Asst. Prof. Dawud Thongtha",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "c869514d-5c29-4e83-90ca-2bd9a9750abb",
      title:
        "An Analytical Approximation of Option Prices via TGARCH Model,2020",
      publish_year: 202,
      journal: "Economic Research-Ekonomska Istraživanja",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "835fc675-b551-4aa9-9d47-fe355f0d1653",
        name: "ผศ.ดร.ดาวุด ทองทา",
        english_name: "Asst. Prof. Dawud Thongtha",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "020c9ea1-514d-41f0-9909-6a9caf5e0e56",
      title:
        "Extexsion of Almost Generalized Weakly Contractive Mappings in Rectangular b-matric Spaces and Fixed Point Results, 2017",
      publish_year: 2017,
      journal: "Afrika Matematika",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "835fc675-b551-4aa9-9d47-fe355f0d1653",
        name: "ผศ.ดร.ดาวุด ทองทา",
        english_name: "Asst. Prof. Dawud Thongtha",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "f2104da2-33a4-4c92-b9da-ce613a7d5162",
      title:
        "Hilbert series of typical representations for lie superalgebras, 2021",
      publish_year: 2021,
      journal: "Journal of Lie Theory",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7ahw8f",
        name: "ผศ. ดร.ทรงพล ศรีวงค์ษา",
        english_name: "Asst. Prof. Dr.Songpon Sriwongsa",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "3dd15609-b8a9-4305-a877-38eab07639d2",
      title:
        "Branching from the General Linear Group to the Symmetric Group and the Principal Embedding, 2021",
      publish_year: 2021,
      journal: "Algebraic Combinatorics",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7ahw8f",
        name: "ผศ. ดร.ทรงพล ศรีวงค์ษา",
        english_name: "Asst. Prof. Dr.Songpon Sriwongsa",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "c23cab37-7650-47a3-9155-c7cea2ea9397",
      title:
        "Orthogonal decompositions of Lie algebras over finite commutative rings, 2021",
      publish_year: 2021,
      journal: "Journal of Algebra and its Applications",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7ahw8f",
        name: "ผศ. ดร.ทรงพล ศรีวงค์ษา",
        english_name: "Asst. Prof. Dr.Songpon Sriwongsa",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "7696a10e-4307-4502-a428-c01878728416",
      title:
        "Characterization of nilpotent Lie algebras of breadth 3 over finite fields of odd characteristic, 2021",
      publish_year: 2021,
      journal: "JOURNAL OF ALGEBRA",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7ahw8f",
        name: "ผศ. ดร.ทรงพล ศรีวงค์ษา",
        english_name: "Asst. Prof. Dr.Songpon Sriwongsa",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "3c9910f8-8922-4111-8c72-bdac02ffc86f",
      title: "Orthogonal graphs modulo power of 2, 2020",
      publish_year: 2020,
      journal: "Linear and Multilinear Algebra",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7ahw8f",
        name: "ผศ. ดร.ทรงพล ศรีวงค์ษา",
        english_name: "Asst. Prof. Dr.Songpon Sriwongsa",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "9008da45-2a76-4a8d-b7e4-863e4af489ea",
      title: "The triangle inequality for graded real vector spaces, 2020",
      publish_year: 2020,
      journal: "MATHEMATICAL INEQUALITIES & APPLICATIONS",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7ahw8f",
        name: "ผศ. ดร.ทรงพล ศรีวงค์ษา",
        english_name: "Asst. Prof. Dr.Songpon Sriwongsa",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "39d87a9d-c9c2-4073-93a5-cc38be5f7b7a",
      title:
        "A Diffusion model of 18F-FDOPA in the diagnosis of Parkinson's disease and its numerical simulation, 2021",
      publish_year: 2021,
      journal: "ScienceAsia",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7et58f",
        name: "ผศ.ดร.ธีระพล สลีวงศ์",
        english_name: "Asst. Prof.Dr.Teeapol Saleewong",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "027ea090-1008-46a6-9b52-e482121678f0",
      title:
        "Compartmental Model for Transportation of 177Lu-DOTATATE into Critical Organs, 2020",
      publish_year: 2020,
      journal: "TEST Engineering & Management",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7et58f",
        name: "ผศ.ดร.ธีระพล สลีวงศ์",
        english_name: "Asst. Prof.Dr.Teeapol Saleewong",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "c07ae0dc-2fe6-4ab6-af50-83e41bbfe4cc",
      title:
        "Fixed and Periodic Point Results for Generalized Geraghty Contractions in Partial Metric Spaces with Application,2020",
      publish_year: 2020,
      journal: "Thai Journal of Mathematics",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7et58f",
        name: "ผศ.ดร.ธีระพล สลีวงศ์",
        english_name: "Asst. Prof.Dr.Teeapol Saleewong",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "8a1ce022-556f-4db5-a52b-c3dd408aad38",
      title:
        "Shrinking approximants for fixed point problem and generalized split null point problem in Hilbert spaces, 2021",
      publish_year: 2021,
      journal: "Optimization Letters",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ผศ.ดร.ปริญญา เสงี่ยมสุนทร",
        english_name: "Asst. Prof. Dr. Parinya Sa Ngiamsunthorn",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "282aa280-815a-4cfc-bcde-0fd91e9390d3",
      title:
        "Parallel shrinking inertial extragradient approximants for pseudomonotone equilibrium, fixed point and generalized split null point problem,2021",
      publish_year: 2021,
      journal: "Ricerche di Matematica",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ผศ.ดร.ปริญญา เสงี่ยมสุนทร",
        english_name: "Asst. Prof. Dr. Parinya Sa Ngiamsunthorn",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "3cd1a913-4721-43fe-be2b-b93cb0722feb",
      title:
        "Fixed-Time Synergetic Approach for Biological Pest Control Based on Lotka-Volterra Model, 2021",
      publish_year: 2021,
      journal: "IEEE Access",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ผศ.ดร.ปริญญา เสงี่ยมสุนทร",
        english_name: "Asst. Prof. Dr. Parinya Sa Ngiamsunthorn",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "c4bdbbc3-5124-4e47-8e25-505c9f61d6e6",
      title:
        "An epidemic prediction from Analysis of a combined HIV-COVID-19 co-infection model via ABC-fractional operator,2021",
      publish_year: 2021,
      journal: "Alexandria Engineering Journal",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.ปรินทร์ ไชยปัญญา",
        english_name: "Asst. Prof. Dr. Parinya Sa Ngiamsunthorn",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "5a7e60b8-b956-4367-a5e7-76fe9d15c627",
      title:
        "Splitting algorithm of common solutions between equilibrium and inclusion problems on Hadamard manifolds, 2021",
      publish_year: 2021,
      journal: "Linear and Nonlinear Analysis",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.ปรินทร์ ไชยปัญญา",
        english_name: "Asst. Prof. Dr. Parinya Sa Ngiamsunthorn",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: "63fb2d29-9a54-4e32-9008-d00f588b9f24",
      title:
        "Adaptive Kernel Approach to Inverse Problems and Image Restoration using Block Coordinate Descent, 2021",
      publish_year: 2021,
      journal: "IEEE",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.ปรินทร์ ไชยปัญญา",
        english_name: "Asst. Prof. Dr. Parinya Sa Ngiamsunthorn",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "Clustering and Exploring of Gene Functional Modules from Cassava Root Gene Expression Data, 2021",
      publish_year: 2021,
      journal: "KMUTT Research and Development Journal",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.พรทิพย์ เดชพิชัย",
        english_name: "Asst. Prof. Dr. Parinya Sa Ngiamsunthorn",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "Purchasing Forecast of Business Customers : Case Study of Agro-Industrial Business, 2021",
      publish_year: 2021,
      journal: "The 18th KU KPS National Conference",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.พรทิพย์ เดชพิชัย",
        english_name: "Asst. Prof. Dr. Parinya Sa Ngiamsunthorn",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "การจัดกลุ่มสถานีตรวจอากาศ อุตุนิยมวิทยา ตามปัจจัยสภาพอากาศจากแบบจำลอง Conformal Cubic Atmospheric Model (CCAM), 2021",
      publish_year: 2021,
      journal: "The 25th Annual Meeting in Mathematics: AMM 2021",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.พรทิพย์ เดชพิชัย",
        english_name: "Asst. Prof. Dr. Parinya Sa Ngiamsunthorn",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title: "การพยากรณ์การสั่งซื้อผลิตภัณฑ์อะไหล่ทองเหลือง, 2021",
      publish_year: 2021,
      journal: "The 25th Annual Meeting in Mathematics: AMM 2021",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.พรทิพย์ เดชพิชัย",
        english_name: "Asst. Prof. Dr. Parinya Sa Ngiamsunthorn",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "Factors associated with health self-rated of Thai elderly using multinomial logistic regression, 2020",
      publish_year: 2020,
      journal: "การประชุมวิชาการระดับชาตินเรศวรวิจัยและนวัตกรรม ครั้งที่ 16",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.พรทิพย์ เดชพิชัย",
        english_name: "Asst. Prof. Dr. Parinya Sa Ngiamsunthorn",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "Cluster Analysis and Binary Logistic Regression for Power Station Classification using Power Quality, 2020",
      publish_year: 2020,
      journal:
        "The 11th National Conference of Industrial Operations Development 2020",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.พรทิพย์ เดชพิชัย",
        english_name: "Asst. Prof. Dr. Parinya Sa Ngiamsunthorn",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "A Comparison of Electricity Consumption Forecast Models of Bangkok, 2019",
      publish_year: 2019,
      journal:
        "The 1st Undergraduate Scientist Meeting of Science, Mathematics and Science Education : USMSE-2019",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.พรทิพย์ เดชพิชัย",
        english_name: "Asst. Prof. Dr. Parinya Sa Ngiamsunthorn",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "The Comparison of Predicative Model for Saving of the Elderly in Thailand, 2019",
      publish_year: 2019,
      journal:
        "The 1st Undergraduate Scientist Meeting of Science, Mathematics and Science Education : USMSE-2019",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.พรทิพย์ เดชพิชัย",
        english_name: "Asst. Prof. Dr. Parinya Sa Ngiamsunthorn",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "The pooled prevalence of allergic disease in Thai children: Meta-analysis, 2017",
      publish_year: 2017,
      journal: "Advanced Science",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.พรทิพย์ เดชพิชัย",
        english_name: "Asst. Prof. Dr. Parinya Sa Ngiamsunthorn",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "An Interest and Factors Promoting a Safe Walk-to- and Bike-to-Skytrain Commuting: Bangwa-Talingchan Case study, 2017",
      publish_year: 2017,
      journal:
        "The 5th Thailand Bike and Walk Forum : Walk and Bike Friendly Community",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.พรทิพย์ เดชพิชัย",
        english_name: "Asst. Prof. Dr. Parinya Sa Ngiamsunthorn",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "Connected domination critical graphs with a block having maximum number of cut vertices",
      publish_year: 2021,
      journal: "Applied Mathematics and Computation ",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "รศ. ดร.ภวธน เขมะวิชานุรัตน์",
        english_name: "Assoc. Prof. Dr.Pawaton Kaemawichanurat",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "A cop and drunken robber game on n-dimensional infinite-grid graphs",
      publish_year: 2021,
      journal: "Mathematics",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "รศ. ดร.ภวธน เขมะวิชานุรัตน์",
        english_name: "Assoc. Prof. Dr.Pawaton Kaemawichanurat",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title: "Structural properties of connected domination critical graphs",
      publish_year: 2021,
      journal: "Mathematics",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "รศ. ดร.ภวธน เขมะวิชานุรัตน์",
        english_name: "Assoc. Prof. Dr.Pawaton Kaemawichanurat",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "Inequalities between the K-k-isolation number and the independent K-k-isolation number of a graph",
      publish_year: 2021,
      journal: "Discrete Applied Mathematics",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "รศ. ดร.ภวธน เขมะวิชานุรัตน์",
        english_name: "Assoc. Prof. Dr.Pawaton Kaemawichanurat",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "Two Hybrid Spectral Methods With Inertial Effect for Solving System of Nonlinear Monotone Equations With Application in Robotics",
      publish_year: 2021,
      journal: "in IEEE Access",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ศ.ดร.ภูมิ คำเอม",
        english_name: "Prof. Poom Kumam",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "Weak Convergence and Strong Convergence of Nonmonotonic Explicit Iterative Methods for Solving Equilibrium Problems",
      publish_year: 2021,
      journal: "Journal of Nonlinear and Convex Analysis",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ศ.ดร.ภูมิ คำเอม",
        english_name: "Prof. Poom Kumam",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "FRACTIONAL MAGNETOHYDRODYNAMIC FLOW OF A SECOND GRADE FLUID IN A POROUS MEDIUM WITH VARIABLE WALL VELOCITY AND NEWTONIAN HEATING",
      publish_year: 2021,
      journal: "Fractals",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ศ.ดร.ภูมิ คำเอม",
        english_name: "Prof. Poom Kumam",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "Weak Convergence and Strong Convergence of Nonmonotonic Explicit Iterative Methods for Solving Equilibrium Problems",
      publish_year: 2021,
      journal: "Journal of Nonlinear and Convex Analysis",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ศ.ดร.ภูมิ คำเอม",
        english_name: "Prof. Poom Kumam",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "FRACTIONAL MAGNETOHYDRODYNAMIC FLOW OF A SECOND GRADE FLUID IN A POROUS MEDIUM WITH VARIABLE WALL VELOCITY AND NEWTONIAN HEATING",
      publish_year: 2021,
      journal: "Fractals",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ศ.ดร.ภูมิ คำเอม",
        english_name: "Prof. Poom Kumam",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "The Investigation of the Fractional-View Dynamics of Helmholtz Equations Within Caputo Operator",
      publish_year: 2021,
      journal: "Computers, Materials & Continua",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ศ.ดร.ภูมิ คำเอม",
        english_name: "Prof. Poom Kumam",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "Fractional Dynamics of HIV with Source Term for the Supply of New CD4+ T-Cells Depending on the Viral Load via Caputo–Fabrizio Derivative",
      publish_year: 2021,
      journal: "Molecules 2021",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ศ.ดร.ภูมิ คำเอม",
        english_name: "Prof. Poom Kumam",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "A Geometrical Approach to the Diophantine Equation x^{2}_{1}+x_{2}^{2}+x_{3}^{2}+...+x_{n}^{2}=u^{2}",
      publish_year: 2021,
      journal: "Burapha Science Journal",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.รัชนิกร ชลไชยะ",
        english_name: "Dr. Ratchanikorn Chonchaiya",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "The Effect of Cognitively Guided Instruction Learning Management with Emphasizing Higher Order Questions in Elementary Counting Principle Upon Mathematics Learning Achievement and Mathematical Problem Solving Ability of Matthayom Suksa 4 Students",
      publish_year: 2021,
      journal: "The 5th UTCC National Conference",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.รัชนิกร ชลไชยะ",
        english_name: "Dr. Ratchanikorn Chonchaiya",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "A Study on Fliipped Classroom based on Donald and Maki's approach for Developing Mathematical Connection in Exponential and Logarithmic Functions to Real-Life Situations for Grade 10 Students",
      publish_year: 2021,
      journal: "International Conference The Future of Education",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.รัชนิกร ชลไชยะ",
        english_name: "Dr. Ratchanikorn Chonchaiya",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "The Development of Mathematical Reasoning Ability for Mathayomsuksa 2 Students by Using Jigsaw Cooperative Learning",
      publish_year: 2018,
      journal: "International Conference The Future of Education",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ผศ.ดร.วราภรณ์ จาตนิล",
        english_name: "Asst. Prof. Dr. Waraporn Chatanin",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "การพัฒนาความสามารถในการให้เหตุผลทางคณิตศาสตร์ของนักเรียนชั้นมัธยมศึกษาปีที่ 2 โดยอาศัยการจัดการเรียนรู้แบบร่วมมือเทคนิคปริศนาความคิด",
      publish_year: 2018,
      journal: "วารสารศึกษาศาสตร์ มหาวิทยาลัยสงขลานครินทร์ วิทยาเขตปัตตานี",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ผศ.ดร.วราภรณ์ จาตนิล",
        english_name: "Asst. Prof. Dr. Waraporn Chatanin",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "การพัฒนาสื่อการเรียนการสอนเรื่องปริพันธ์หลายชั้นสำหรับนักศึกาาระดับปริญญาตรีโดยใช้ ADDIE Model",
      publish_year: 2021,
      journal: "การประชุมวิชาการระดับชาติ ครั้งที่ 7 ( TECHCON 2020)",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ผศ.ดร.วราภรณ์ จาตนิล",
        english_name: "Asst. Prof. Dr. Waraporn Chatanin",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "THE EFFECT OF ACTIVE LEARNING TOWARDS LEARNING ACHIEVEMENT AND MATHEMATICAL PROBLEM SOLVING ABILITY ON TRIGONOMETRY OF MATTHAYOM SUKSA 3 STUDENTS",
      publish_year: 2021,
      journal:
        "การประชุมวิชาการระดับชาติพิบูลสงครามวิจัย ครั้งที่ 7 ประจำปี พ.ศ. 2565",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ผศ.ดร.วราภรณ์ จาตนิล",
        english_name: "Asst. Prof. Dr. Waraporn Chatanin",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "Learning Management Using 5 Steps Learning Process for Improving Geometric Reasoning Ability of Matthayom Suksa 3 Students.",
      publish_year: 2021,
      journal:
        "การประชุมวิชาการระดับชาติ “การศึกษาเพื่อพัฒนาการเรียนรู้ ครั้งที่ 6 ประจำปี 2565”",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ผศ.ดร.วราภรณ์ จาตนิล",
        english_name: "Asst. Prof. Dr. Waraporn Chatanin",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "Learning Management by the Storyline Approach and Bar Models on Word Problems of Addition and Subtraction",
      publish_year: 2021,
      journal:
        "การประชุมวิชาการระดับชาติราชภัฏหมูู่บ้านจอมบึงวิจัย ครั้งที่ 10",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ผศ.ดร.วราภรณ์ จาตนิล",
        english_name: "Asst. Prof. Dr. Waraporn Chatanin",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "Ensemble-AHTPpred: A Robust Ensemble Machine Learning Model Integrated With a New Composite Feature for Identifying Antihypertensive Peptides”, ",
      publish_year: 2021,
      journal:
        "Frontiers in Genetics, Vol. 13, pp. 950. https://doi.org/10.3389/fgene.2022.883766",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.วรินทร์ วัฒนพรพรหม",
        english_name: "Dr.Warin Wattanapornprom",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "mSRFR: a machine learning model using microalgal signature features for ncRNA classification”,",
      publish_year: 2021,
      journal:
        "BioData Mining, Vol. 15, No. 8, https://doi.org/10.1186/s13040-022-00291-0",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.วรินทร์ วัฒนพรพรหม",
        english_name: "Dr.Warin Wattanapornprom",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "Ensemble of Multiple Classifiers for Multilabel Classification of Plant Protein Subcellular Localization”, ",
      publish_year: 2022,
      journal:
        "MDPI Life, Vol. 11, No. 4, pp. 293–308. https://doi.org/10.3390/life11040293",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.วรินทร์ วัฒนพรพรหม",
        english_name: "Dr.Warin Wattanapornprom",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "Gaussian Quantum-Behaved Particle Swarm with Learning Automata-Adaptive Attractor and Local Search",
      publish_year: 2022,
      journal: "",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.วรินทร์ วัฒนพรพรหม",
        english_name: "Dr.Warin Wattanapornprom",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title: "Two layers cloud radiative forcing model",
      publish_year: 2019,
      journal:
        "ARPN Journal of Engineering and Applied Sciences, 14(3), pp. 700-707. สืบค้นได้ในฐานข้อมูล Scopus",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ผศ. ดร.วริสา ยมเสถียรกุล",
        english_name: "Asst. Prof. Dr. Warisa Yomsatieankul",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        " Non-oscillatory central differencing scheme for solving traffic flow model based on non-linear velocity density function",
      publish_year: 2020,
      journal:
        "IAENG International Journal of Applied Mathematics, 50(1), pp. 216-224. สืบค้นได้ในฐานข้อมูล Scopus",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ผศ. ดร.วริสา ยมเสถียรกุล",
        english_name: "Asst. Prof. Dr. Warisa Yomsatieankul",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "The consequences of global warming due to ice-albedo feedback and greenhouse effect in an energy balance daisyworld model",
      publish_year: 2018,
      journal:
        "Applied Ecology and Environmental Research, 16(2), pp. 1483-1505. สืบค้นได้ในฐานข้อมูล Scopus",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ผศ. ดร.วริสา ยมเสถียรกุล",
        english_name: "Asst. Prof. Dr. Warisa Yomsatieankul",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "Analysis and modeling of fractional electro-osmotic ramped flow of chemically reactive and heat absorptive/generative Walters'B fluid with ramped heat and mass transfer rates",
      publish_year: 2021,
      journal: "AIMS Mathematics",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "รศ. ดร.วิบูลศักดิ์ วัฒายุ",
        english_name: "Assoc. Prof. Dr.Wiboonsak Watthayu",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "Double Slip Effects and Heat Transfer Characteristics for Channel Transport of Engine Oil With Titanium and Aluminum Alloy Nanoparticles: A Fractional Study",
      publish_year: 2021,
      journal: "IEEE Access",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "รศ. ดร.วิบูลศักดิ์ วัฒายุ",
        english_name: "Assoc. Prof. Dr.Wiboonsak Watthayu",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "A Projection Hestenes–Stiefel Method with Spectral Parameter for Nonlinear Monotone Equations and Signal Processing",
      publish_year: 2021,
      journal: "Math. Comput. Appl.",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "รศ. ดร.วิบูลศักดิ์ วัฒายุ",
        english_name: "Assoc. Prof. Dr.Wiboonsak Watthayu",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title: "Competitive Numerical Method for an Avian Influenza Model",
      publish_year: 2018,
      journal: "WSEAS TRANSACTIONS ON SYSTEMS",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "รศ.ดร.เศรษฐภัทร ชินวิริยสิทธิ์",
        english_name: "Assoc. Prof. Dr. Settapat Chinviriyasit",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "The effect of backward bifurcation in controlling measles transmission by vaccination",
      publish_year: 2019,
      journal: "Chaos, Solitons and Fractals",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "รศ.ดร.เศรษฐภัทร ชินวิริยสิทธิ์",
        english_name: "Assoc. Prof. Dr. Settapat Chinviriyasit",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "Mathematical evaluation of the role of cross immunity and nonlinear incidence rate on the transmission dynamics of two dengue serotypes",
      publish_year: 2020,
      journal: "Advances in Difference Equations",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "รศ.ดร.เศรษฐภัทร ชินวิริยสิทธิ์",
        english_name: "Assoc. Prof. Dr. Settapat Chinviriyasit",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title: "A model of infection in honeybee colonies with social immunity",
      publish_year: 2021,
      journal: "PLoS ONE",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.อนุวัฒน์ ตั้งธนวัฒน์สกุล",
        english_name: "Dr. Anuwat Tangthanawatsakul",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "A Mathematical Model of Stochastic Phase Transitions in Breast Cancer Development",
      publish_year: 2020,
      journal: "Solid State Technology",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.อนุวัฒน์ ตั้งธนวัฒน์สกุล",
        english_name: "Dr. Anuwat Tangthanawatsakul",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    {
      research_id: uuidv4(),
      title:
        "A cop and drunken robber game on n-dimensional infinite-grid graphs",
      publish_year: 2021,
      journal: "Mathematics",
      impact_factor: 0,
      research_area: "",
      research_type: "",
      description: "",
      quartile: 0,
      research_paper: false,
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_6: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: 0,
      option_14: 0,
      option_15: 0,
      option_16: 0,
      option_17: 0,
      option_18: 0,
      option_19: 0,
      createdAt: "",
      updatedAt: "",
      researcher_id: {
        id: "e35d2596-503d-4693-92cc-5f1ebc7a151f",
        name: "ดร.อนุวัฒน์ ตั้งธนวัฒน์สกุล",
        english_name: "Dr. Anuwat Tangthanawatsakul",
        education: [""],
        research_area: [""],
        email: "",
        tel: "",
        createdAt: "",
        updatedAt: "",
      },
    },
  ],
  status: 200,
};
