import type { Research_Option } from "../interfaces/ResearchOption";

export const Statistic: Research_Option = {
  option_1: [2, 3, 2, 7, 0],
  option_2: [0, 1, 1, 8, 1],
  option_3: [0, 0, 0, 0, 0],
  option_4: [0, 0, 0, 5, 0],
  option_5: [2, 0, 0, 1, 0],
  option_6: [3, 5, 8, 19, 2],
  option_7: [0, 0, 0, 0, 0],
  option_8: [0, 0, 0, 0, 0],
  option_9: [0, 0, 0, 0, 0],
  option_10: [0, 0, 0, 0, 0],
  option_11: [0, 0, 0, 0, 0],
  option_12: [0, 0, 0, 0, 0],
  option_13: [0, 0, 0, 0, 0],
  option_14: [0, 0, 0, 0, 0],
  option_15: [0, 0, 0, 0, 0],
  option_16: [0, 0, 0, 0, 0],
  option_17: [0, 0, 0, 0, 0],
  option_18: [0, 0, 0, 0, 0],
  option_19: [0, 0, 0, 0, 0],
};
