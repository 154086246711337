import React, { useEffect, useState } from "react";
import { SingleValue } from "react-select";
import CarouselSlider from "../components/Carousel/CarouselSlider";
import Navbar from "../components/Navbar";
import Selected from "../components/Selected";
import Pagination from "../components/Table/Pagination";
import ResearchTable from "../components/Table/ResearchTable";
import { ResearchStoreImpl } from "../store/ResearchStore";
import { observer } from "mobx-react";
import { BiSearchAlt2 } from "react-icons/bi";
import { Quartile } from "../mocks/quartile";
import AccessTokenProvider from "../provider/AccessTokenProvider";
import { Research_type_option } from "../mocks/research_type_option";
import { FetchResearchBySelect } from "../api/researchTable/fetchResearchBySelect";
import { RESEARCHER } from "../mocks/researcher";

interface ResearchCollectionProps {
  researchStore: ResearchStoreImpl;
}

interface FormDataType {
  researcher_id: string;
  quertile: number;
  research_type: string;
}

const ResearchCollection: React.FC<ResearchCollectionProps> = observer(
  (props) => {
    useEffect(() => {
      async function fetch() {
        const response = await FetchResearchBySelect({
          quartile: 0,
          research_type: "",
          researcher_id: "",
        });
      }

      // fetch(); // <- uncomment this line to fetch data from server
    }, []);

    const [formData, setFormData] = useState<FormDataType>({
      researcher_id: "",
      quertile: 0,
      research_type: "",
    });

    const handleChangeResearch = (
      selected: SingleValue<{ value: string; label: string }>
    ) => {
      props.researchStore.setResearchSearchOption(selected?.value as string);
    };

    const handleChangeResearcher = (
      selected: SingleValue<{ value: string; label: string }>
    ) => {
      setFormData({
        ...formData,
        researcher_id: selected?.value as string,
      });
    };

    const handleChangeQuartile = (
      selected: SingleValue<{ value: string; label: string }>
    ) => {
      setFormData({
        ...formData,
        quertile: parseInt(selected?.value as string),
      });
    };

    const handleChangeResearchType = (
      selected: SingleValue<{ value: string; label: string }>
    ) => {
      setFormData({
        ...formData,
        research_type: selected?.value as string,
      });
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
      e.preventDefault();

      // v- uncomment below to fetch data from server
      /*

      props.researchStore.fetchData({
        research_type:
        formData.research_type === "ALL" ? "" : formData.research_type,
        quartile: formData.quertile,
        researcher_id:
        formData.researcher_id === "ALL" ? "" : formData.researcher_id,
      }); 

      */
    };

    return (
      <AccessTokenProvider>
        <div className="overflow-hidden relative">
          <Navbar />
          <div className="mt-[9vh] shadow-lg">
            <CarouselSlider />
          </div>
          <div className="flex justify-center flex-col items-center">
            <div className="flex py-10 gap-4 w-[60vw] max-md:flex-col">
              <div>
                <div className="flex justify-between max-lg:flex-col max-md:gap-5 gap-5 py-3">
                  <Selected
                    handleChange={handleChangeResearchType}
                    placeholder="ชนิดของงานวิจัย"
                    data={Research_type_option}
                    custom="w-[30vw] max-lg:w-[60vw]"
                  />
                  <Selected
                    handleChange={handleChangeQuartile}
                    placeholder="ระดับคุณภาพผลงาน"
                    data={Quartile}
                    custom="w-[30vw] max-lg:w-[60vw]"
                  />
                </div>
                <div className="flex gap-x-5 gap-y-1 max-lg:flex-col">
                  <Selected
                    handleChange={handleChangeResearcher}
                    placeholder="ชื่อผู้เขียน"
                    custom="py-2 w-full max-lg:w-[60vw]"
                    data={RESEARCHER.map((item) => {
                      return {
                        value: item.id,
                        label: item.name,
                      };
                    })}
                  />
                  <Selected
                    handleChange={handleChangeResearch}
                    placeholder="ชื่องานวิจัย"
                    custom="py-2 w-full max-lg:w-[60vw]"
                    data={props.researchStore.getResearchCollenctionOption}
                  />
                </div>
              </div>
              <div className="flex items-center justify-center">
                <BiSearchAlt2
                  className="hover:bg-gray-300 rounded-full w-[4rem] h-[4rem] border-[1px] m-auto p-4 duration-75 ease-in-out cursor-pointer"
                  onClick={handleSubmit}
                />
              </div>
            </div>
            <div>
              <ResearchTable researchStore={props.researchStore} />
            </div>
            <Pagination researchStore={props.researchStore} />
          </div>
          <div className=" w-[20rem] h-[100vh] rotate-45 absolute bottom-[-30%] right-[-20rem] bg-orange_theme max-md:hidden z-1"></div>
        </div>
      </AccessTokenProvider>
    );
  }
);

export default ResearchCollection;
