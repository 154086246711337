import React from "react";
import Layout from "../../../components/layouts/layout";
export const EditResearcher = () => {
    return (
        <Layout>
            
        </Layout>
    )
}

export default EditResearcher;