import React from 'react';
import Layout from "../../components/layouts/layout";
export const Dashboard = () => {
	return (
		<Layout>
            <p className="mb-3 text-xl font-bold">ภาพรวม</p>
			<div className='bg-white p-3 drop-shadow rounded-md'>
                
            </div>
		</Layout>
	);
};

export default Dashboard;