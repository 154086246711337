import React, { useEffect } from "react";
import { ValidateToken } from "../api/authorization/validateToken";
import { useNavigate } from "react-router-dom";

const AccessTokenProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const navigate = useNavigate();
  useEffect(() => {
    const validateToken = async () => {
      const response = await ValidateToken();
      if (!response.valid) {
        navigate("/login");
      }
    };
    // validateToken(); // <- comment this line to disable token validation
  }, [navigate]);

  return <div>{props.children}</div>;
};

export default AccessTokenProvider;
