import React, { useState } from "react";
import SignIn from "../common/SignIn.common";
import TextField from "../common/TextField.common";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { regexEmail } from "../helpers/regexEmail";
import { RegisterRouter } from "../api/authentication/register";
import { HttpStatus } from "../api/httpStatus";
import { WEBSITE_PATH } from "../config/Path";

interface FormData {
  email: string;
  password: string;
  confirmPassword: string;
}

const Register: React.FC = () => {
  const navigate = useNavigate();
  const [closePassword, setClosePassword] = useState<boolean>(true);

  const [formData, setFormData] = useState<FormData>({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errorForm, setErrorForm] = useState<FormData>({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { email, password, confirmPassword } = formData;

    setErrorForm({
      email: "",
      password: "",
      confirmPassword: "",
    });

    if (!regexEmail(email) || email === "") {
      setErrorForm({
        ...errorForm,
        email: "Invalid email",
      });
      return;
    }

    if (confirmPassword !== password) {
      setErrorForm({
        ...errorForm,
        confirmPassword: "Passwords do not match",
        password: " ",
      });
      return;
    }

    if (password.length < 6) {
      setErrorForm({
        ...errorForm,
        password: "Password must be at least 6 characters",
      });
      return;
    }

    // can submit
    const response = await RegisterRouter({
      email,
      password,
    });

    if (response.status === HttpStatus.CREATED) {
      navigate(`${WEBSITE_PATH.WAIT_WERIFY_EMAIL}${email.split("@")[0]}`);
    } else if (response.status === HttpStatus.BAD_REQUEST) {
      setErrorForm({
        ...errorForm,
        email: response.message,
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <SignIn submit={onSubmit} title="Sign up">
      <div className="gap-3 flex flex-col">
        <TextField
          placeholder="email"
          name="email"
          onChange={handleChange}
          error={`${errorForm.email ? errorForm.email : ""}`}
          type="email"
          value={formData.email}
        />
        <div className="relative">
          <TextField
            placeholder="password"
            name="password"
            onChange={handleChange}
            error={`${errorForm.password ? errorForm.password : ""}`}
            type={closePassword ? "password" : "text"}
            value={formData.password}
          />
          <div>
            {closePassword ? (
              <AiOutlineEye
                className="absolute right-2 top-3 cursor-pointer z-10 bg-white"
                size={20}
                onClick={() => setClosePassword(!closePassword)}
              />
            ) : (
              <AiOutlineEyeInvisible
                className="absolute right-2 top-3 cursor-pointer bg-white"
                onClick={() => setClosePassword(!closePassword)}
                size={20}
              />
            )}
          </div>
        </div>
        <TextField
          placeholder="confirm your password"
          name="confirmPassword"
          onChange={handleChange}
          value={formData.confirmPassword}
          error={`${
            errorForm.confirmPassword ? errorForm.confirmPassword : ""
          }`}
          type={closePassword ? "password" : "text"}
          maxLength={20}
          minLength={6}
        />
      </div>
    </SignIn>
  );
};

export default Register;
