import React from "react";
import { ChartStore } from "./store/ChartStore";
import { ResearchStore } from "./store/ResearchStore";

import { Route, Routes } from "react-router-dom";
import { WEBSITE_PATH } from "./config/Path";

// import Pages
import Dashboard from "./pages/admin/dashboard";
import AddData from "./pages/admin/research/AddData";
import Chart from "./pages/Chart";
import Login from "./pages/Login";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Register from "./pages/Register";
import ResearchCollection from "./pages/ResearchCollection";
import ResearchDetail from "./pages/ResearchDetail";
import EditResearch from "./pages/admin/research/Edit";
import MenuResearch from "./pages/admin/research/Menu";
import EditResearcher from "./pages/admin/researcher/EditResearcher";
import MenuResearcher from "./pages/admin/researcher/MenuResearcher";
import WaitVerifyEmail from "./pages/WaitVerifyEmail";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/admin" element={<Dashboard />} />
      <Route path="/add/research" element={<AddData />} />
      <Route path="/edit/research" element={<MenuResearch />} />
      <Route path="/edit/research">
        <Route path=":DocId" element={<EditResearch />} />
      </Route>
      <Route path="/edit/researcher" element={<MenuResearcher />} />
      <Route path="/edit/researcher">
        <Route path=":DocId" element={<EditResearcher />} />
      </Route>

      <Route path={WEBSITE_PATH.LOGIN} element={<Login />} />
      <Route path={WEBSITE_PATH.REGISTER} element={<Register />} />

      <Route path={WEBSITE_PATH.HOME} element={<Home />} />
      <Route
        path={WEBSITE_PATH.STATISTIC}
        element={<Chart chartStore={ChartStore} />}
      />
      <Route
        path={WEBSITE_PATH.RESEARCH}
        element={<ResearchCollection researchStore={ResearchStore} />}
      />
      <Route
        path={WEBSITE_PATH.RESEARCH_DETAIL}
        element={<ResearchDetail researchStore={ResearchStore} />}
      />
      <Route
        path={WEBSITE_PATH.WAIT_WERIFY_EMAIL}
        element={<WaitVerifyEmail />}
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
